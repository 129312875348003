<template>
  <div>
    <!-- for images -->
    <div v-if="fnGetExtension(imgUrl) != 'pdf'" class="text-center">
      <img
        draggable="false"
        :src="tempUrl"
        :class="imgClass"
        :style="imgStyle"
      />
    </div>

    <!-- for pdf -->
    <div v-if="fnGetExtension(imgUrl) == 'pdf'">
      <embed
        draggable="false"
        type="text/html"
        :src="tempUrl"
        height="900px"
        width="100%"
      />
    </div>
  </div>
</template>
<script>
import * as miscellaneousService from "../../services/miscellaneous.service";
export default {
  name: "ModelImageAvatarPreview",
  props: {
    imgClass: {
      type: String,
      default: "",
    },
    imgStyle: {
      type: String,
      default: "",
    },
    imgUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tempUrl: "",
    };
  },
  methods: {
    // To get file extension
    fnGetExtension(fileName) {
      return miscellaneousService.getExtension(fileName);
    },
    fnGetEncryptedUrl: function (imgUrl) {
      let url = "";
      url = miscellaneousService.fnGetTempUrl(imgUrl);
      return url;
    },
  },
  async mounted() {
    this.tempUrl = await this.fnGetEncryptedUrl(this.imgUrl);
    //document.oncontextmenu = new Function("return false");
  },
  watch: {
    async imgUrl(newVal) {
      this.tempUrl = await this.fnGetEncryptedUrl(newVal);
      //document.oncontextmenu = new Function("return false");
    },
  },
};
</script>
<style lang="scss">
@import "../../scss/_colors.scss";
</style>
