<template>
  <div class="row">
    <div class="col-lg-9 col-md-9 col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              <h5>Review & Submit</h5>
              <div class="underline"></div>
            </div>
          </div>

          <appReviewBlock1 :propsData="getPoliceCheckSingle">
          </appReviewBlock1>
          <appReviewBlock2
            v-if="this.loginUser.role_id == 1 || this.loginUser.role_id == 2"
            :propsData="getPoliceCheckSingle"
          >
          </appReviewBlock2>

          <div
            class="col-xl-12 col-lg-12 col-md-12 col-12 mt-3"
            v-if="this.loginUser.role_id == 3 || this.loginUser.role_id == 4"
          >
            <h5 class="text-info">Consent</h5>
            <dl class="row">
              <dt class="col-sm-2">General</dt>
              <dd class="col-sm-10">
                <p>
                  Our information services are provided in accordance with our
                  standard Terms and Conditions accessible at
                  <a href="//cvscreen.com.au/terms-conditions/" target="_blank"
                    >www.cvscreen.com.au/terms-conditions/</a
                  >.
                </p>
              </dd>

              <dt class="col-sm-2">I am over 18 years of age</dt>
              <dd class="col-sm-10">
                <div>
                  <b-form-group
                    label="If you are less than 18 years of age, the National Police Check form must be co-signed by your parent or legal guardian"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-radio
                      v-model="yearsOfAge"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      value="O"
                      >I am over 18 years of age</b-form-radio
                    >
                    <b-form-radio
                      v-model="yearsOfAge"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      value="U"
                      >I am under 18 years of age</b-form-radio
                    >
                  </b-form-group>
                  <div class="invalid-feedback col-12" v-if="errors.yearsOfAge">
                    Age consent is required
                  </div>
                </div>
              </dd>

              <dt class="col-sm-2">National Police Check</dt>
              <dd class="col-sm-10">
                I, {{ getPoliceCheckSingle.profile_info.first_name }}
                {{ getPoliceCheckSingle.profile_info.last_name }}, herby
                consent to CV Screen Pty Ltd using my personal details to
                conduct or procure the conduct of a National Police Check.
              </dd>
              <dt class="col-sm-2">Signature</dt>
              <dd class="col-sm-10">
                <div class="invalid-feedback" v-if="errors.signature">
                  {{ errors.signature[0] }}
                </div>
                <VueSignaturePad
                  width="100%"
                  height="200px"
                  ref="signaturePad"
                  class="border border-secondary"
                />
                <div class="mt-2">
                  <button
                    class="btn btn-info btn-sm"
                    @click="fnSignaturePadUndo"
                  >
                    Undo
                  </button>
                </div>
              </dd>
              <dt class="col-sm-2"></dt>
              <dd class="col-sm-10 mt-3">
                <wdImage
                  v-if="getPoliceCheckSingle.signature"
                  :imgUrl="getPoliceCheckSingle.signature"
                  :imgStyle="'height: 100px; width: auto'"
                  :imgClass="'card-img-top'"
                ></wdImage>
              </dd>

              <dt class="col-sm-2" v-if="yearsOfAge == 'U'">
                Parent or Legal Guardian Signature
              </dt>
              <dd class="col-sm-10" v-if="yearsOfAge == 'U'">
                <div class="invalid-feedback" v-if="errors.guardianSignature">
                  {{ errors.guardianSignature[0] }}
                </div>
                <VueSignaturePad
                  width="100%"
                  height="200px"
                  ref="guardianSignaturePad"
                  class="border border-secondary"
                />
                <div class="mt-2">
                  <button
                    class="btn btn-primary btn-sm"
                    @click="fnGuardianSignaturePadUndo"
                  >
                    Undo
                  </button>
                </div>
              </dd>
              <dt class="col-sm-2" v-if="yearsOfAge == 'U'"></dt>
              <dd class="col-sm-10 mt-3" v-if="yearsOfAge == 'U'">
                <wdImage
                  v-if="getPoliceCheckSingle.guardianSignature"
                  :imgUrl="getPoliceCheckSingle.guardianSignature"
                  :imgStyle="'height: 100px; width: auto'"
                  :imgClass="'card-img-top'"
                ></wdImage>
              </dd>
            </dl>
          </div>

          <!-- Partner and Partner Staff consent -->
          <div
            class="row"
            v-if="this.loginUser.role_id == 6 || this.loginUser.role_id == 7"
          >
            <div class="col-12 col-lg-12 col-md-12 mt-5">
              <h5 class="text-info">Consent</h5>
              <p>
                Our information services are provided in accordance with our
                standard Terms and Conditions accessible at
                <a
                  href="https://cvscreen.com.au/terms-conditions/"
                  target="_blank"
                  >here</a
                >.
              </p>
              <p class="">
                Please upload the completed applicant consent form.
              </p>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
              <div class="alert alert-danger mb-3 col-12" v-if="msg.length > 0">
                <p
                  class="fs-800 mb-0"
                  v-for="(item, index) in msg"
                  :key="index"
                >
                  {{ item }}
                </p>
              </div>

              <appFileUploadComSingle
                :policeCheckId="policeCheckId"
                :doc_urls="propsData.consent_partner"
                :documentTypeId="81"
                :documentType="'Consent partner'"
              ></appFileUploadComSingle>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-right pt2000">
              <button
                class="btn-secondary btn-sm mr-2 btn-w-100"
                @click="fnPreviousStep()"
              >
                Previous
              </button>
              <button
                v-if="this.loginUser.role_id == 3"
                class="btn-info btn-sm btn-w-100"
                @click="fnSignatureSave()"
              >
                <span
                  ><b-spinner small v-if="loading" class="mr-2"></b-spinner
                  >Submit</span
                >
              </button>

              <!-- Partner submit -->
              <button
                v-if="
                  this.loginUser.role_id == 6 || this.loginUser.role_id == 7
                "
                class="btn-info btn-sm btn-w-100"
                @click="fnPartnerSubmit()"
              >
                <span
                  ><b-spinner small v-if="loading" class="mr-2"></b-spinner
                  >Submit</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-12">
      <div class="card" v-if="loginUser.role_id != 1">
        <div class="card-body h-overflow">
          <appStep6Ins></appStep6Ins>
        </div>
      </div>
      <div v-if="this.loginUser.role_id == 1 || this.loginUser.role_id == 2">
        <appCheckList1
          :policeCheckId="policeCheckId"
          :propsData="propsData"
        ></appCheckList1>
      </div>
      <!-- Note section -->
      <div v-if="this.loginUser.role_id == 1 || this.loginUser.role_id == 2">
        <checkListNotesCom
          :checkId="policeCheckId"
          :productId="productId"
          :propsNotesData="propsNotesData"
        ></checkListNotesCom>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import * as messageService from "../../services/message.service";
import appStep6Ins from "../../components/policeCheck/step6Ins.vue";
import * as policeCheckService from "../../services/policeCheck.service";
import appReviewBlock1 from "../../components/policeCheck/reviewBlock1.vue";
import appReviewBlock2 from "../../components/policeCheck/reviewBlock2.vue";
import appCheckList1 from "./pcCheckListCom.vue";
import checkListNotesCom from "../notes/CheckListNotesCom.vue";
import appFileUploadComSingle from "../common/FileUploadComSingle.vue";
import wdImage from "../../components/wd/WDImage.vue";

export default {
  name: "step6",
  props: {
    propsNotesData: {
      type: Object,
    },
    productId: {
      type: Number,
      require: true,
    },
    policeCheckId: {
      type: Number,
      require: true,
    },
  },

  components: {
    appStep6Ins,
    appReviewBlock1,
    appReviewBlock2,
    appCheckList1,
    checkListNotesCom,
    appFileUploadComSingle,
    wdImage,
  },
  data() {
    return {
      loading: false,
      errors: {},
      yearsOfAge: "",
      loginUser: {},
      notesData: [],
      propsData: {},
      msg: [],
    };
  },
  computed: {
    ...mapGetters(["getPoliceCheckSingle"]),
  },
  methods: {
    ...mapActions(["fetchStepId"]),
    fnPreviousStep() {
      this.fetchStepId(5);
    },
    fnsubmit() {},
    fnSignaturePadUndo() {
      this.$refs.signaturePad.undoSignature();
    },
    fnGuardianSignaturePadUndo() {
      this.$refs.guardianSignaturePad.undoSignature();
    },

    fnSignaturePadSave() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      return data;
    },
    fnGuardianSignaturePadSave() {
      const { isEmpty, data } = this.$refs.guardianSignaturePad.saveSignature();
      return data;
    },
    // Individual user
    fnSignatureSave: async function () {
      this.loading = true;

      let guardianSignatureData = null;
      let signatureData = null;

      signatureData = this.fnSignaturePadSave();

      if (this.yearsOfAge === "U") {
        guardianSignatureData = this.fnGuardianSignaturePadSave();
      }

      let obj = {
        yearsOfAge: this.yearsOfAge,
        signature: signatureData,
        guardianSignature: guardianSignatureData,
        status: 2,
      };

      try {
        let res = "";

        res = await policeCheckService.signatureUpload(this.policeCheckId, obj);

        this.errors = {};

        messageService.fnToastSuccess("Application updated successfully!");
        if (res.status === 200) {
          this.$router.push({ name: "policeCheckSubmittedMessage" });

          this.loading = false;
        }
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Signature - Something wrong, please try again!",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Signature - new",
              "Something wrong, please try again!"
            );
            break;
        }
        messageService.fnToastError("Something wrong, please try again!");

        this.loading = false;
      }
    },

    // Partner & Partner Staff submit the client's information
    fnPartnerSubmit: async function () {
      this.msg = [];
      if (
        this.propsData.consent_partner == null ||
        this.propsData.consent_partner == "" ||
        this.propsData.consent_partner == "null" ||
        this.propsData.consent_partner == "undefine"
      ) {
        this.msg.push(
          "Consent is required for CV Screen to process a police check. Please upload the applicant's consent."
        );
        return;
      }

      this.loading = true;

      let obj = {
        status: 2,
        is_auto_notification: true,
      };

      try {
        let res = "";

        res = await policeCheckService.updateStatus(this.policeCheckId, obj);

        this.errors = {};

        messageService.fnToastSuccess("Application updated successfully!");
        if (res.status === 200) {
          this.$router.push({ name: "policeCheckSubmittedMessagePartner" });

          this.loading = false;
        }
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Signature - Something wrong, please try again!",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Signature - new",
              "Something wrong, please try again!"
            );
            break;
        }
        messageService.fnToastError("Something wrong, please try again!");

        this.loading = false;
      }
    },
  },
  created() {
    this.loginUser = authService.getUserFromToken();
  },
  watch: {
    getPoliceCheckSingle: function (val) {
      if (val) {
        this.propsData = val;
      }
    },
  },
};
</script>
