<template>
  <!-- 8 -->
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
      <div class="form-group">
        <textarea
          class="form-control"
          type="text"
          autocomplete="off_doc1_cannot_provide_document"
          placeholder=" "
          :value="value.cannot_provide_document"
          @input="updateValue($event)"
          ref="cannot_provide_document"
          rows="4"
        ></textarea>
        <label class="floatingLabel"
          >Provide reasons why you cannot provide the document</label
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TypeFrom8",
  props: ["policeCheckId", "form_no", "doc_urls", "value"],
  components: {},
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(["fetchPoliceCheckSingle"]),
    updateValue(e) {
      this.$emit("input", {
        cannot_provide_document: this.$refs.cannot_provide_document.value,
      });
    },
  },
};
</script>

