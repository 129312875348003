<template>
  <div class="card card-timeline border-none pt-2 pb-4 h-100">
    <ul class="bs4-order-tracking mt1000">
      <li
        class="step"
        v-bind:class="{ active: propsData == 1, done: propsData > 1 }"
      >
        <div class="image">
          <img src="../../assets/img/police_badge_white.svg" />
        </div>
        <p class="mb-0">Police Check Details</p>
      </li>
      <li
        class="step"
        v-bind:class="{ active: propsData == 2, done: propsData > 2 }"
      >
        <div class="image">
          <img src="../../assets/img/search_white.svg" />
        </div>
        <p class="mb-0">Basic Details</p>
      </li>
      <li
        class="step"
        v-bind:class="{ active: propsData == 3, done: propsData > 3 }"
        v-if="shId == 1"
      >
        <div class="image">
          <img src="../../assets/img/credit_card_white.svg" />
        </div>
        <p class="mb-0">Pay</p>
      </li>
      <li
        class="step"
        v-bind:class="{ active: propsData == 4, done: propsData > 4 }"
      >
        <div class="image">
          <img src="../../assets/img/search_white.svg" />
        </div>
        <p class="mb-0">Additional Details</p>
      </li>
      <li
        class="step"
        v-bind:class="{ active: propsData == 5, done: propsData > 5 }"
      >
        <div class="image">
          <img src="../../assets/img/file_white.svg" />
        </div>
        <p class="mb-0">Upload Identity Documents</p>
      </li>
      <li
        class="step"
        v-bind:class="{ active: propsData == 6, done: propsData > 6 }"
      >
        <div class="image">
          <img src="../../assets/img/check_list_white.svg" />
        </div>
        <p class="mb-0">Review & Submit</p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["propsData", "shId"],
  name: "progressSteps",
};
</script>