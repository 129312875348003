<template>
    <div class="row">
        <div class="col-12">
            <h5>Police Check Type Instructions</h5>
            <b-alert variant="info" show>
                <p class="mb-0">Please select one of police check type</p>
            </b-alert>

            <b-alert variant="info" v-if="gettPoliceCheckType == 1" show>
                <p class="fw-600 lh1500">What is a standard police check?</p>
                <p>A standard police check is required for paid work, where you are an employee, contractor, staff member or receive any other entitlements associated with your role or position.</p>
                <p class="fw-600">What are the standard police check categories?</p>
                <ul>
                    <li><span class="fw-600">Employment</span> - Paid or unpaid work. Unpaid work is when you are not paid however an employment type relationship exists, for example an internship or work experience.</li>
                    <li><span class="fw-600">Probity</span> - Representing yourself as a “fit and proper” person, such as, for a voluntary positions, membership, and tenancy applications.</li>
                    <li><span class="fw-600">License</span> - Undertaking Licenced or regulatory activities, such as applying for a gaming licence or for registration as a greyhound trainer.</li>
                </ul>
            </b-alert>
            <b-alert variant="info" v-if="gettPoliceCheckType == 2" show>
                <p class="fw-600 lh1500">What is a volunteer police check?</p>
                <p>The volunteer police check is intended for applicants seeking to uptake a genuine volunteer position.</p>
                <p>A volunteer check can be requested if the applicant will:</p>

                <div class="d-flex flex-row mb-2">
                    <div class="pr-3">
                        ✓
                    </div>
                    <div class="">
                        Freely hold the position or perform the role on a voluntary basis for the common good, <span class="fw-600">and</span>
                    </div>
                </div>
                <div class="d-flex flex-row mb-2">
                    <div class="pr-3">
                        ✓
                    </div>
                    <div class="">
                        Not be an employee or contractor and will not be entitled to a salary or any other entitlements associated with the position or role, 
                        other than payment of out-of-pocket expenses.
                    </div>
                </div>
                <div class="d-flex flex-row">
                    <div class="pr-3">
                        <p class="fw-600">Or:</p>
                    </div>
                </div>
                <div class="d-flex flex-row mb-2">
                    <div class="pr-3">
                        ✓
                    </div>
                    <div class="">
                        Is a student undertaking a compulsory unpaid vocational placement as part of a training course requirement of an Australian-based educational institution.
                    </div>
                </div>
                <p>The Volunteer check type cannot be selected for the Australian Government’s Work for the Dole Scheme.</p>
            </b-alert>
            
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name:'step1Ins',
    data(){
        return{
           
        }
    },
     computed:{
      ...mapGetters([
        'gettPoliceCheckType',
        ]),
    },
}

</script>