<template>
  <div class="card sits-border-info">
    <div class="card-header">
      <h5 class="text-info mb-0">Current Address Details</h5>
    </div>
    <div class="card-body" v-if="propsData && propsData.profile_info">
      <dl class="row">
        <dd class="col-sm-4 fw-700">Unit number</dd>
        <dd class="col-sm-8">{{ propsData.profile_info.unit_no }}</dd>
        <dd class="col-sm-4 fw-700">Street number</dd>
        <dd class="col-sm-8">{{ propsData.profile_info.street_no }}</dd>
        <dd class="col-sm-4 fw-700">Street</dd>
        <dd class="col-sm-8">{{ propsData.profile_info.street }}</dd>
        <dd class="col-sm-4 fw-700">Suburb</dd>
        <dd class="col-sm-8">{{ propsData.profile_info.suburb }}</dd>
        <dd class="col-sm-4 fw-700">State</dd>
        <dd class="col-sm-8">{{ propsData.profile_info.state.name }}</dd>
        <dd class="col-sm-4 fw-700">Post code</dd>
        <dd class="col-sm-8">{{ propsData.profile_info.post_code }}</dd>
        <dd class="col-sm-4 fw-700">Country</dd>
        <dd class="col-sm-8">
          {{ propsData.profile_info.get_country.name }}
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
export default {
  name: "pcCurrentAddressDetailsCom",
  props: {
    propsData: {
      required: true,
      type: Object,
    },
  },
};
</script>