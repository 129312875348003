<template>
  <div>
    <dl class="row">
      <dd class="col-sm-6 text-secondary font-weight-normal">State</dd>
      <dd class="col-sm-6">{{ propsData.state }}</dd>
      <dd class="col-sm-6 text-secondary font-weight-normal">Given name</dd>
      <dd class="col-sm-6">{{ propsData.given_name }}</dd>
      <dd class="col-sm-6 text-secondary font-weight-normal">Middle name</dd>
      <dd class="col-sm-6">{{ propsData.middle_name }}</dd>
      <dd class="col-sm-6 text-secondary font-weight-normal">Family name</dd>
      <dd class="col-sm-6">{{ propsData.family_name }}</dd>
      <dd class="col-sm-6 text-secondary font-weight-normal">
        Registration no
      </dd>
      <dd class="col-sm-6">{{ propsData.reference_no }}</dd>
      <dd class="col-sm-6 text-secondary font-weight-normal">
        Date certificate issued
      </dd>
      <dd class="col-sm-6">{{ propsData.date_certificate_issued }}</dd>
    </dl>
  </div>
</template>
<script>
export default {
  name: "WDfrmDis09",
  props: ["propsData"],
};
</script>