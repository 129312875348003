<template>
  <div>
    <dl class="row">
      <dd class="col-sm-6 text-secondary font-weight-normal">Given name</dd>
      <dd class="col-sm-6">{{ propsData.given_name }}</dd>
      <dd class="col-sm-6 text-secondary font-weight-normal">Family name</dd>
      <dd class="col-sm-6">{{ propsData.family_name }}</dd>
      <dd class="col-sm-6 text-secondary font-weight-normal">
        Passport number
      </dd>
      <dd class="col-sm-6">{{ propsData.reference_no }}</dd>
      <dd class="col-sm-6 text-secondary font-weight-normal">Country</dd>
      <dd class="col-sm-6">{{ propsData.country }}</dd>
    </dl>
  </div>
</template>
<script>
export default {
  name: "WDfrmDis03",
  props: ["propsData"],
};
</script>