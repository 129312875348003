<template>
  <div class="row">
    <div class="col-lg-9 col-md-9 col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              <h4>Basic Details</h4>
              <div class="underline"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="text-info">Personal details</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="firstName"
                  autocomplete="off_firstName"
                  placeholder=" "
                  v-model="step2.first_name"
                />
                <label class="floatingLabel" for="firstName">Given name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="middleName"
                  autocomplete="off_middleName"
                  placeholder=" "
                  v-model="step2.middle_name"
                />
                <label class="floatingLabel" for="firstName">Middle name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="lastName"
                  autocomplete="off_lastName"
                  placeholder=" "
                  v-model="step2.last_name"
                />
                <label class="floatingLabel" for="lastName">Family name</label>
                <div class="invalid-feedback col-12" v-if="errors.last_name">
                  {{ errors.last_name[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <b-form-checkbox
                v-if="step2.first_name == '' || step2.first_name == null"
                id="checkbox-1"
                name="checkbox-1"
                value="No"
                unchecked-value="Yes"
                v-model="step2.have_a_given_name"
              >
                Does not have a given name
              </b-form-checkbox>
              <div
                class="invalid-feedback col-12"
                v-if="errors.have_a_given_name"
              >
                {{ errors.have_a_given_name[0] }}
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <b-form-checkbox
                v-if="step2.middle_name == '' || step2.middle_name == null"
                id="checkbox-2"
                name="checkbox-2"
                value="No"
                unchecked-value="Yes"
                v-model="step2.have_a_middle_name"
              >
                Does not have a middle name
              </b-form-checkbox>
              <div
                class="invalid-feedback col-12"
                v-if="errors.have_a_middle_name"
              >
                {{ errors.have_a_middle_name[0] }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="date"
                  id="dateOfBirth"
                  autocomplete="off_dateOfBirth"
                  placeholder=" "
                  v-model="step2.date_of_birth"
                />
                <label class="floatingLabel" for="dateOfBirth"
                  >Date of birth</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.date_of_birth"
                >
                  {{ errors.date_of_birth[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-4 col-12">
              <div class="form-group">
                <b-form-select
                  v-model="step2.gender_code"
                  :options="optionsGender"
                >
                </b-form-select>
                <label class="floatingLabel vueBootSelect" for="position_title"
                  >Gender</label
                >
                <div class="invalid-feedback col-12" v-if="errors.gender_code">
                  {{ errors.gender_code[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="email"
                  autocomplete="off_email"
                  placeholder=" "
                  v-model="step2.email"
                  disabled
                />
                <label class="floatingLabel" for="email">Email</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="mobileNo"
                  autocomplete="off_mobileNo"
                  placeholder=" "
                  v-model="step2.mobile_no"
                />
                <label class="floatingLabel" for="mobileNo">Mobile no</label>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12">
              <p class="text-info">
                Birth Place
                <span class="fs-800 text-danger"
                  >(As shown on your documents)</span
                >
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group multiselect">
                <input
                  class="form-control"
                  type="text"
                  id="country_of_birth_suburb"
                  autocomplete="off_Suburb"
                  placeholder=" "
                  v-model="step2.country_of_birth_suburb"
                />
                <label class="floatingLabel vueBootSelect"
                  >Suburb / Town of birth</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.country_of_birth_suburb"
                >
                  {{ errors.country_of_birth_suburb[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group multiselect">
                <b-form-select
                  :options="optionsState"
                  v-model="step2.country_of_birth_state"
                >
                </b-form-select>
                <label class="floatingLabel vueBootSelect"
                  >State of birth</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.country_of_birth_state"
                >
                  {{ errors.country_of_birth_state[0] }}
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group multiselect">
                <b-form-select
                  value-field="alpha_3"
                  text-field="name"
                  :options="getSettingAll.country"
                  v-model="step2.country_of_birth"
                >
                </b-form-select>
                <label class="floatingLabel vueBootSelect"
                  >Country of birth</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.country_of_birth"
                >
                  {{ errors.country_of_birth[0] }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <p class="text-info">Current address details</p>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="unit_no"
                  autocomplete="off_unit_no"
                  placeholder=" "
                  v-model="step2.unit_no"
                />
                <label class="floatingLabel" for="unit_no">Unit number</label>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="street_no"
                  autocomplete="off_street_no"
                  placeholder=" "
                  v-model="step2.street_no"
                />
                <label class="floatingLabel" for="street_no"
                  >Street number</label
                >
                <div class="invalid-feedback col-12" v-if="errors.street_no">
                  {{ errors.street_no[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="street"
                  autocomplete="off_street"
                  placeholder=" "
                  v-model="step2.street"
                />
                <label class="floatingLabel" for="street"
                  >Street Name and Type</label
                >
                <div class="invalid-feedback col-12" v-if="errors.street">
                  {{ errors.street[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="suburb"
                  autocomplete="off_suburb"
                  placeholder=" "
                  v-model="step2.suburb"
                />
                <label class="floatingLabel" for="suburb">Suburb</label>
                <div class="invalid-feedback col-12" v-if="errors.suburb">
                  {{ errors.suburb[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-12">
              <b-form-select v-model="step2.state_code" :options="optionsState">
              </b-form-select>
              <label class="floatingLabel vueBootSelect" for="state"
                >State</label
              >
              <div class="invalid-feedback col-12" v-if="errors.state_code">
                {{ errors.state_code[0] }}
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="postCode"
                  autocomplete="off_postCode"
                  placeholder=" "
                  v-model="step2.post_code"
                />
                <label class="floatingLabel" for="postCode">Post code</label>
                <div class="invalid-feedback col-12" v-if="errors.post_code">
                  {{ errors.post_code[0] }}
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-3 col-12">
              <div class="form-group multiselect">
                <b-form-select
                  value-field="alpha_3"
                  text-field="name"
                  :options="getSettingAll.country"
                  v-model="step2.country_code"
                >
                </b-form-select>
                <label class="floatingLabel vueBootSelect">Country</label>
                <div class="invalid-feedback col-12" v-if="errors.country_code">
                  {{ errors.country_code[0] }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-right pt2000">
              <button
                class="btn-secondary btn-sm mr-2 btn-w-100"
                @click="fnPreviousStep()"
              >
                Previous
              </button>
              <button class="btn-info btn-sm btn-w-100" @click="fnNextStep()">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-12">
      <div class="card h-overflow">
        <div class="card-body">
          <appStep2Ins></appStep2Ins>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as policeCheckService from "../../services/policeCheck.service";
import * as messageService from "../../services/message.service";
import appStep2Ins from "../../components/policeCheck/step2Ins.vue";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";

export default {
  name: "step2",
  props: ["mode", "propsData", "policeCheckId"],
  components: {
    appStep2Ins,
  },
  data() {
    return {
      loginUserObj: {},
      errors: {},
      step2: {
        gender_code: "M",
        first_name: "",
        middle_name: "",
        last_name: "",
        date_of_birth: "",
        country_of_birth_code: "Australia",
        email: "",
        mobile_no: "",
        have_a_given_name: "Yes",
        have_a_middle_name: "Yes",
        have_a_family_name: "Yes",
        unit_no: "",
        street_no: "",
        street: "",
        suburb: "",
        state_code: "",
        post_code: "",
        country_code: "",
      },
      optionsGender: [
        { value: "M", text: "Male" },
        { value: "F", text: "Female" },
        { value: "U", text: "Unknown or not supplied" },
        { value: "X", text: "Intersex" },
      ],
      optionsState: [
        { value: "NSW", text: "New South Wales" },
        { value: "QLD", text: "Queensland" },
        { value: "SA", text: "South Australia" },
        { value: "TAS", text: "Tasmania" },
        { value: "VIC", text: "Victoria" },
        { value: "WA", text: "Western Australia" },
        { value: "ACT", text: "Australian Capital Territory" },
        { value: "NT", text: "Northern Territory" },
        { value: "OTHER", text: "Other" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getSettingAll", "getUserFromId", "getPoliceCheckSingle"]),
  },
  methods: {
    ...mapActions(["fetchStepId", "fetchUserFromId", "fetchPoliceCheckSingle"]),
    fnPreviousStep() {
      this.fetchStepId(1);
    },
    fnNextStep() {
      this.fnUpdateUserInfo();
    },
    fnUpdateUserInfo: async function () {
      try {
        if (this.step2.first_name) {
          this.step2.have_a_given_name = "Yes";
        }
        if (this.step2.middle_name) {
          this.step2.have_a_middle_name = "Yes";
        }
        const response = await policeCheckService.updateUserInfo(
          this.step2.id,
          this.step2
        );

        this.error = {};

        messageService.fnToastSuccess("User information updated successfully!");

        if (response.data.status_code == 200) {
          this.fetchPoliceCheckSingle(this.propsData.id);

          // To Skip payment step for the Partner and partner staff
          if (
            this.getPoliceCheckSingle.sh_id == 2 ||
            this.getPoliceCheckSingle.sh_id == 3
          ) {
            this.fetchStepId(4);
          } else {
            this.fetchStepId(3);
          }

          // if (
          //   this.loginUserObj.role_id == 6 ||
          //   this.loginUserObj.role_id == 7
          // ) {
          //   this.fetchStepId(4);
          // } else {
          //   this.fetchStepId(3);
          // }
        } else {
          return;
        }
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "User",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "User",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "User information",
              "Something wrong, please try again!"
            );
            break;
        }
      }
    },
  },

  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchPoliceCheckSingle(this.policeCheckId);
  },

  watch: {
    getPoliceCheckSingle: function (val) {
      if (val) {
        this.step2 = val.profile_info;
      }
    },
  },
};
</script>
