<template>
    <div>
        <dl class="row">
            <dd class="col-sm-6 text-secondary font-weight-normal">Card type</dd>
            <dd class="col-sm-6">{{ propsData.card_type }}</dd>
            <dd class="col-sm-6 text-secondary font-weight-normal">Medicare no</dd>
            <dd class="col-sm-6">{{ propsData.reference_no }}</dd>
            <dd class="col-sm-6 text-secondary font-weight-normal">Individual reference no</dd>
            <dd class="col-sm-6">{{ propsData.individual_reference_no }}</dd>
            <dd class="col-sm-6 text-secondary font-weight-normal">Name per document</dd>
            <dd class="col-sm-6">{{ propsData.name_per_document }}</dd>
            <dd class="col-sm-6 text-secondary font-weight-normal">Expiry date</dd>
            <dd class="col-sm-6">{{ propsData.expiry_date }}</dd>
        </dl>                      
    </div>
</template>
<script>
export default {
    name:'WDfrmDis07',
    props:['propsData'],
}
</script>