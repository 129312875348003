<template>
  <div class="row">
    <div class="col-lg-9 col-md-9 col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              <h5>Upload Identity Documents</h5>
              <div class="underline"></div>
            </div>
          </div>

          <div class="row">
            <!-- ------------------------------------------------------------------------------------ -->
            <div class="col-lg-6 col-md-6 col-12">
              <div class="card sits-border-info pl-3 pr-3 pt-3 pb-3 mb-5">
                <div class="row">
                  <div
                    class="alert alert-danger mb-5 col-12"
                    v-if="commencementValidation.length > 0"
                  >
                    <p
                      class="fs-800 mb-0"
                      v-for="(item, index) in commencementValidation"
                      :key="index"
                    >
                      {{ item }}
                    </p>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="form-group">
                      <b-form-select
                        v-model="commencement_doc_no"
                        value-field="id"
                        text-field="name"
                        :options="getSettingAll.documentTypeDropdown1"
                        @change="fnCommencementDoc(commencement_doc_no)"
                      >
                      </b-form-select>
                      <label
                        class="floatingLabel vueBootSelect"
                        for="position_title"
                        >Commencement</label
                      >
                    </div>
                  </div>

                  <div class="col-12">
                    <appType1
                      v-if="commencement_form_no == 1"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.commencement_doc_urls"
                      :documentTypeId="51"
                      :documentType="'Commencement doc'"
                      v-model="step5.doc1"
                    ></appType1>

                    <appType2
                      v-if="commencement_form_no == 2"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.commencement_doc_urls"
                      :documentTypeId="51"
                      :documentType="'Commencement doc'"
                      v-model="step5.doc1"
                    ></appType2>

                    <appType3
                      v-if="commencement_form_no == 3"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.commencement_doc_urls"
                      :documentTypeId="51"
                      :documentType="'Commencement doc'"
                      v-model="step5.doc1"
                    ></appType3>

                    <appType4
                      v-if="commencement_form_no == 4"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.commencement_doc_urls"
                      :documentTypeId="51"
                      :documentType="'Commencement doc'"
                      v-model="step5.doc1"
                    ></appType4>

                    <appType5
                      v-if="commencement_form_no == 5"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.commencement_doc_urls"
                      :documentTypeId="51"
                      :documentType="'Commencement doc'"
                      v-model="step5.doc1"
                    ></appType5>

                    <appType6
                      v-if="commencement_form_no == 6"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.commencement_doc_urls"
                      :documentTypeId="51"
                      :documentType="'Commencement doc'"
                      v-model="step5.doc1"
                    ></appType6>

                    <appType7
                      v-if="commencement_form_no == 7"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.commencement_doc_urls"
                      :documentTypeId="51"
                      :documentType="'Commencement doc'"
                      v-model="step5.doc1"
                    ></appType7>

                    <appType8
                      v-if="commencement_form_no == 8"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.commencement_doc_urls"
                      :documentTypeId="51"
                      :documentType="'Commencement doc'"
                      v-model="step5.doc1"
                    ></appType8>

                    <appType9
                      v-if="commencement_form_no == 9"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.commencement_doc_urls"
                      :documentTypeId="51"
                      :documentType="'Commencement doc'"
                      v-model="step5.doc1"
                    ></appType9>
                  </div>
                </div>
              </div>
            </div>

            <!-- ------------------------------------------------------------------------------------ -->
            <div class="col-lg-6 col-md-6 col-12">
              <div class="card sits-border-info pl-3 pr-3 pt-3 pb-3 mb-5">
                <div class="row">
                  <div
                    class="alert alert-danger mb-5 col-12"
                    v-if="primaryValidation.length > 0"
                  >
                    <p
                      class="fs-800 mb-0"
                      v-for="(item, index) in primaryValidation"
                      :key="index"
                    >
                      {{ item }}
                    </p>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="form-group">
                      <b-form-select
                        v-model="primary_doc_no"
                        value-field="id"
                        text-field="name"
                        :options="getSettingAll.documentTypeDropdown2"
                        @change="fnPrimaryDoc(primary_doc_no)"
                      >
                      </b-form-select>
                      <label
                        class="floatingLabel vueBootSelect"
                        for="position_title"
                        >Primary</label
                      >
                    </div>
                  </div>

                  <div class="col-12">
                    <appType1
                      v-if="primary_form_no == 1"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.primary_doc_urls"
                      :documentTypeId="52"
                      :documentType="'Primary doc'"
                      v-model="step5.doc2"
                    ></appType1>

                    <appType2
                      v-if="primary_form_no == 2"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.primary_doc_urls"
                      :documentTypeId="52"
                      :documentType="'Primary doc'"
                      v-model="step5.doc2"
                    ></appType2>

                    <appType3
                      v-if="primary_form_no == 3"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.primary_doc_urls"
                      :documentTypeId="52"
                      :documentType="'Primary doc'"
                      v-model="step5.doc2"
                    ></appType3>

                    <appType4
                      v-if="primary_form_no == 4"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.primary_doc_urls"
                      :documentTypeId="52"
                      :documentType="'Primary doc'"
                      v-model="step5.doc2"
                    ></appType4>

                    <appType5
                      v-if="primary_form_no == 5"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.primary_doc_urls"
                      :documentTypeId="52"
                      :documentType="'Primary doc'"
                      v-model="step5.doc2"
                    ></appType5>

                    <appType6
                      v-if="primary_form_no == 6"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.primary_doc_urls"
                      :documentTypeId="52"
                      :documentType="'Primary doc'"
                      v-model="step5.doc2"
                    ></appType6>

                    <appType7
                      v-if="primary_form_no == 7"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.primary_doc_urls"
                      :documentTypeId="52"
                      :documentType="'Primary doc'"
                      v-model="step5.doc2"
                    ></appType7>

                    <appType8
                      v-if="primary_form_no == 8"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.primary_doc_urls"
                      :documentTypeId="52"
                      :documentType="'Primary doc'"
                      v-model="step5.doc2"
                    ></appType8>

                    <appType9
                      v-if="primary_form_no == 9"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.primary_doc_urls"
                      :documentTypeId="52"
                      :documentType="'Primary doc'"
                      v-model="step5.doc2"
                    ></appType9>
                  </div>
                </div>
              </div>
            </div>

            <!-- ------------------------------------------------------------------------------------ -->
            <div class="col-lg-6 col-md-6 col-12">
              <div class="card sits-border-info pl-3 pr-3 pt-3 pb-3 mb-3">
                <div class="row">
                  <div
                    class="alert alert-danger mb-5 col-12"
                    v-if="secondary1Validation.length > 0"
                  >
                    <p
                      class="fs-800 mb-0"
                      v-for="(item, index) in secondary1Validation"
                      :key="index"
                    >
                      {{ item }}
                    </p>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="form-group">
                      <b-form-select
                        v-model="secondary1_doc_no"
                        value-field="id"
                        text-field="name"
                        :options="getSettingAll.documentTypeDropdown3"
                        @change="fnSecondary1Doc(secondary1_doc_no)"
                      >
                      </b-form-select>
                      <label
                        class="floatingLabel vueBootSelect"
                        for="position_title"
                        >Secondary 1</label
                      >
                    </div>
                  </div>

                  <div class="col-12">
                    <appType1
                      v-if="secondary1_form_no == 1"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary1_doc_urls"
                      :documentTypeId="53"
                      :documentType="'Secondary 1 doc'"
                      v-model="step5.doc3"
                    ></appType1>

                    <appType2
                      v-if="secondary1_form_no == 2"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary1_doc_urls"
                      :documentTypeId="53"
                      :documentType="'Secondary 1 doc'"
                      v-model="step5.doc3"
                    ></appType2>

                    <appType3
                      v-if="secondary1_form_no == 3"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary1_doc_urls"
                      :documentTypeId="53"
                      :documentType="'Secondary 1 doc'"
                      v-model="step5.doc3"
                    ></appType3>

                    <appType4
                      v-if="secondary1_form_no == 4"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary1_doc_urls"
                      :documentTypeId="53"
                      :documentType="'Secondary 1 doc'"
                      v-model="step5.doc3"
                    ></appType4>

                    <appType5
                      v-if="secondary1_form_no == 5"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary1_doc_urls"
                      :documentTypeId="53"
                      :documentType="'Secondary 1 doc'"
                      v-model="step5.doc3"
                    ></appType5>

                    <appType6
                      v-if="secondary1_form_no == 6"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary1_doc_urls"
                      :documentTypeId="53"
                      :documentType="'Secondary 1 doc'"
                      v-model="step5.doc3"
                    ></appType6>

                    <appType7
                      v-if="secondary1_form_no == 7"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary1_doc_urls"
                      :documentTypeId="53"
                      :documentType="'Secondary 1 doc'"
                      v-model="step5.doc3"
                    ></appType7>

                    <appType8
                      v-if="secondary1_form_no == 8"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary1_doc_urls"
                      :documentTypeId="53"
                      :documentType="'Secondary 1 doc'"
                      v-model="step5.doc3"
                    ></appType8>

                    <appType9
                      v-if="secondary1_form_no == 9"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary1_doc_urls"
                      :documentTypeId="53"
                      :documentType="'Secondary 1 doc'"
                      v-model="step5.doc3"
                    ></appType9>
                  </div>
                </div>
              </div>
            </div>

            <!-- ------------------------------------------------------------------------------------ -->
            <div class="col-lg-6 col-md-6 col-12">
              <div class="card sits-border-info pl-3 pr-3 pt-3 pb-3 mb-3">
                <div class="row">
                  <div
                    class="alert alert-danger mb-5 col-12"
                    v-if="secondary2Validation.length > 0"
                  >
                    <p
                      class="fs-800 mb-0"
                      v-for="(item, index) in secondary2Validation"
                      :key="index"
                    >
                      {{ item }}
                    </p>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="form-group">
                      <b-form-select
                        v-model="secondary2_doc_no"
                        value-field="id"
                        text-field="name"
                        :options="getSettingAll.documentTypeDropdown3"
                        @change="fnSecondary2Doc(secondary2_doc_no)"
                      >
                      </b-form-select>
                      <label
                        class="floatingLabel vueBootSelect"
                        for="position_title"
                        >Secondary 2</label
                      >
                    </div>
                  </div>

                  <div class="col-12">
                    <appType1
                      v-if="secondary2_form_no == 1"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary2_doc_urls"
                      :documentTypeId="54"
                      :documentType="'Secondary 2 doc'"
                      v-model="step5.doc4"
                    ></appType1>

                    <appType2
                      v-if="secondary2_form_no == 2"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary2_doc_urls"
                      :documentTypeId="54"
                      :documentType="'Secondary 2 doc'"
                      v-model="step5.doc4"
                    ></appType2>

                    <appType3
                      v-if="secondary2_form_no == 3"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary2_doc_urls"
                      :documentTypeId="54"
                      :documentType="'Secondary 2 doc'"
                      v-model="step5.doc4"
                    ></appType3>

                    <appType4
                      v-if="secondary2_form_no == 4"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary2_doc_urls"
                      :documentTypeId="54"
                      :documentType="'Secondary 2 doc'"
                      v-model="step5.doc4"
                    ></appType4>

                    <appType5
                      v-if="secondary2_form_no == 5"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary2_doc_urls"
                      :documentTypeId="54"
                      :documentType="'Secondary 2 doc'"
                      v-model="step5.doc4"
                    ></appType5>

                    <appType6
                      v-if="secondary2_form_no == 6"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary2_doc_urls"
                      :documentTypeId="54"
                      :documentType="'Secondary 2 doc'"
                      v-model="step5.doc4"
                    ></appType6>

                    <appType7
                      v-if="secondary2_form_no == 7"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary2_doc_urls"
                      :documentTypeId="54"
                      :documentType="'Secondary 2 doc'"
                      v-model="step5.doc4"
                    ></appType7>

                    <appType8
                      v-if="secondary2_form_no == 8"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary2_doc_urls"
                      :documentTypeId="54"
                      :documentType="'Secondary 2 doc'"
                      v-model="step5.doc4"
                    ></appType8>

                    <appType9
                      v-if="secondary2_form_no == 9"
                      :policeCheckId="policeCheckId"
                      :doc_urls="getPoliceCheckSingle.secondary2_doc_urls"
                      :documentTypeId="54"
                      :documentType="'Secondary 2 doc'"
                      v-model="step5.doc4"
                    ></appType9>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-12 mt-4">
              <div class="card sits-border-info pl-3 pr-3 pt-3 pb-3 mb-3">
                <b-form-group
                  label="My identity documents are all in the same name (Yes/No)"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    id="radio-group-1"
                    v-model="identity_documents_same_name"
                    :options="optionsIdentityDocumentsSameName"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options"
                    @change="fnIdentityDocumentsSameNameChange()"
                  ></b-form-radio-group>
                  <div
                    class="invalid-feedback col-12"
                    v-if="errors.identity_documents_same_name"
                  >
                    My identity documents are all in the same name or not,
                    confirmation is required
                  </div>
                </b-form-group>
                <div
                  class="alert alert-danger mb-5 col-12"
                  v-if="identityDocumentsSameNameValidation.length > 0"
                >
                  <p
                    class="fs-800 mb-0"
                    v-for="(item, index) in identityDocumentsSameNameValidation"
                    :key="index"
                  >
                    {{ item }}
                  </p>
                </div>
                <div v-if="identity_documents_same_name == 'No'">
                  <appFileUploadCom
                    :policeCheckId="policeCheckId"
                    :doc_urls="identity_documents_same_name_urls"
                    :documentTypeId="61"
                    :documentType="'Change name docs'"
                  ></appFileUploadCom>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-right pt2000">
              <button
                class="btn-secondary btn-sm mr-2 btn-w-100"
                @click="fnPreviousStep()"
              >
                Previous
              </button>
              <button class="btn-info btn-sm btn-w-100" @click="fnNextStep()">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-12">
      <div class="card h-overflow">
        <div class="card-body">
          <appStep5Ins
            :dataProps="step5"
            :commencementDocObj="commencementDocObj"
            :primaryDocObj="primaryDocObj"
            :secondary1DocObj="secondary1DocObj"
            :secondary2DocObj="secondary2DocObj"
          ></appStep5Ins>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import appStep5Ins from "../../components/policeCheck/step5Ins.vue";
import appType1 from "../../components/formsTypes/Type1.vue";
import appType2 from "../../components/formsTypes/Type2.vue";
import appType3 from "../../components/formsTypes/Type3.vue";
import appType4 from "../../components/formsTypes/Type4.vue";
import appType5 from "../../components/formsTypes/Type5.vue";
import appType6 from "../../components/formsTypes/Type6.vue";
import appType7 from "../../components/formsTypes/Type7.vue";
import appType8 from "../../components/formsTypes/Type8.vue";
import appType9 from "../../components/formsTypes/Type9.vue";
import appFileUploadCom from "../../components/common/FileUploadCom.vue";
import * as policeCheckService from "../../services/policeCheck.service";
export default {
  name: "step5",
  props: ["policeCheckId"],
  components: {
    appStep5Ins,
    appType1,
    appType2,
    appType3,
    appType4,
    appType5,
    appType6,
    appType7,
    appType8,
    appType9,
    appFileUploadCom,
  },
  data() {
    return {
      errors: {},

      commencement_form_no: "",
      primary_form_no: "",
      secondary1_form_no: "",
      secondary2_form_no: "",

      commencement_doc_no: "",
      primary_doc_no: "",
      secondary1_doc_no: "",
      secondary2_doc_no: "",

      commencement_doc_name: "",
      primary_doc_name: "",
      secondary1_doc_name: "",
      secondary2_doc_name: "",

      commencement_doc_nss_name: "",
      primary_doc_nss_name: "",
      secondary1_doc_nss_name: "",
      secondary2_doc_nss_name: "",

      step5: {
        doc1: {},
        doc2: {},
        doc3: {},
        doc4: {},
      },

      commencementDocObj: "",
      primaryDocObj: "",
      secondary1DocObj: "",
      secondary2DocObj: "",

      commencementValidation: [],
      primaryValidation: [],
      secondary1Validation: [],
      secondary2Validation: [],
      identityDocumentsSameNameValidation: [],

      commencementDocUrl: [],
      primaryDocUrl: [],
      secondary1DocUrl: [],
      secondary2DocUrl: [],
      identity_documents_same_name_urls: "",
      identity_documents_same_name: "",
      optionsIdentityDocumentsSameName: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
    };
  },

  computed: {
    ...mapGetters(["getSettingAll", "getPoliceCheckSingle"]),
  },
  methods: {
    ...mapActions([
      "fetchStepId",
      "fetchPoliceCheck",
      "fetchPoliceCheckSingle",
    ]),
    fnPreviousStep() {
      this.fetchStepId(4);
    },
    fnNextStep() {
      this.fnUpdatePoliceCheck();
    },

    fnCommencementDoc(event) {
      this.step5.doc1 = {};

      const documentType = this.getSettingAll.documentTypeDropdown1;
      const commencementDocObj = documentType.filter(function (item) {
        return item.id == event;
      });

      this.commencement_doc_name = commencementDocObj[0].name;
      this.commencement_doc_nss_name = commencementDocObj[0].nss_name;
      this.commencement_form_no = commencementDocObj[0].form_type_id;
    },
    fnPrimaryDoc(event) {
      this.step5.doc2 = {};

      const documentType = this.getSettingAll.documentTypeDropdown2;
      const primaryDocObj = documentType.filter(function (item) {
        return item.id == event;
      });
      this.primary_doc_name = primaryDocObj[0].name;
      this.primary_doc_nss_name = primaryDocObj[0].nss_name;
      this.primary_form_no = primaryDocObj[0].form_type_id;
    },
    fnSecondary1Doc(event) {
      this.step5.doc3 = {};

      const documentType = this.getSettingAll.documentTypeDropdown;
      const secondary1DocObj = documentType.filter(function (item) {
        return item.id == event;
      });
      this.secondary1_doc_name = secondary1DocObj[0].name;
      this.secondary1_doc_nss_name = secondary1DocObj[0].nss_name;
      this.secondary1_form_no = secondary1DocObj[0].form_type_id;
    },
    fnSecondary2Doc(event) {
      this.step5.doc4 = {};

      const documentType = this.getSettingAll.documentTypeDropdown;
      const secondary2DocObj = documentType.filter(function (item) {
        return item.id == event;
      });
      this.secondary2_doc_name = secondary2DocObj[0].name;
      this.secondary2_doc_nss_name = secondary2DocObj[0].nss_name;
      this.secondary2_form_no = secondary2DocObj[0].form_type_id;
    },
    fnUpdatePoliceCheck: async function () {
      let upload_identity = {
        commencement_doc_no: this.commencement_doc_no,
        commencement_doc_name: this.commencement_doc_name,
        commencement_doc_nss_name: this.commencement_doc_nss_name,
        commencement_form_no: this.commencement_form_no,
        primary_doc_no: this.primary_doc_no,
        primary_doc_name: this.primary_doc_name,
        primary_doc_nss_name: this.primary_doc_nss_name,
        primary_form_no: this.primary_form_no,
        secondary1_doc_no: this.secondary1_doc_no,
        secondary1_doc_name: this.secondary1_doc_name,
        secondary1_doc_nss_name: this.secondary1_doc_nss_name,
        secondary1_form_no: this.secondary1_form_no,
        secondary2_doc_no: this.secondary2_doc_no,
        secondary2_doc_name: this.secondary2_doc_name,
        secondary2_doc_nss_name: this.secondary2_doc_nss_name,
        secondary2_form_no: this.secondary2_form_no,
        step: this.step5,
      };

      let obj = {
        upload_identity: upload_identity,
        identity_documents_same_name: this.identity_documents_same_name,
      };

      this.commencementValidation = this.fnCheckCommencementValidation();
      this.primaryValidation = this.fnCheckPrimaryValidation();
      this.secondary1Validation = this.fnCheckSecondary1Validation();
      this.secondary2Validation = this.fnCheckSecondary2Validation();
      this.identityDocumentsSameNameValidation = this.fnCheckOtherValidation();

      if (
        this.commencementValidation.length == 0 &&
        this.primaryValidation.length == 0 &&
        this.secondary1Validation.length == 0 &&
        this.secondary2Validation.length == 0 &&
        this.identityDocumentsSameNameValidation.length == 0
      ) {
        try {
          const response = await policeCheckService.updateIdentity(
            this.policeCheckId,
            JSON.stringify(obj)
          );
          if (response.data.status_code == 200) {
            this.fetchPoliceCheckSingle(this.policeCheckId);
            this.fetchStepId(6);
          } else {
            return;
          }

          this.error = {};

          messageService.fnToastSuccess(
            "Identity documents updated successfully!"
          );
        } catch (error) {
          switch (error.response.status) {
            case 422:
              this.errors = error.response.data.errors;
              messageService.fnSweetAlertErrorToast(
                "Select & upload identity documents",
                error.response.data.message
              );
              break;
            case 500:
              messageService.fnSweetAlertErrorToast(
                "Select & upload identity documents",
                error.response.data.message
              );
              break;
            default:
              messageService.fnSweetAlertErrorToast(
                "Select & upload identity documents",
                "Something wrong, please try again!"
              );
              break;
          }
        }
      }
    },

    fnCheckCommencementValidation() {
      // Relevant form for Primary 2, 3, 4, 8, 9
      let msg = [];

      // Check form has empty or not
      if (
        this.commencement_form_no == null ||
        this.commencement_form_no == ""
      ) {
        msg.push(
          "Please select Identity document and provide relevant information and upload document/s"
        );
      }

      //
      if (this.commencement_form_no == 1) {
        if (
          (this.commencementDocUrl && this.commencementDocUrl.length == 0) ||
          this.commencementDocUrl == "" ||
          this.commencementDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // Evidence of Resident Status | ImmiCard
      if (this.commencement_form_no == 2) {
        if (this.isEmptyObject(this.step5.doc1)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1) {
          if (this.step5.doc1.reference_no == "") {
            msg.push("Reference No is required");
          }
        }

        if (this.step5.doc1 == null) {
          msg.push("Relevant information is required");
        }

        if (
          (this.commencementDocUrl && this.commencementDocUrl.length == 0) ||
          this.commencementDocUrl == "" ||
          this.commencementDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // Form no 3
      if (this.commencement_form_no == 3) {
        msg = [];
        if (this.isEmptyObject(this.step5.doc1)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1 == null) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1) {
          if (this.step5.doc1.family_name == "") {
            msg.push("Family Name is required");
          }
          if (this.step5.doc1.passport_no == "") {
            msg.push("Passport no is required");
          }
          if (this.step5.doc1.country == "") {
            msg.push("Country is required");
          }
        }

        if (
          (this.commencementDocUrl && this.commencementDocUrl.length == 0) ||
          this.commencementDocUrl == "" ||
          this.commencementDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // Form no 3
      // Valid Australian Visa (supported by a foreign passport)
      if (this.commencement_doc_no == 40) {
        if (this.step5.doc1.reference_no != this.step5.doc2.number) {
          msg.push(
            "Passport number and Current foreign passport number do not match"
          );
        }

        if (
          (this.commencementDocUrl && this.commencementDocUrl.length == 0) ||
          (this.commencementDocUrl == "" && this.commencementDocUrl == null)
        ) {
          msg.push(
            "Relevant document (Valid Australian Visa) with copy of Foreign Passport  is required, Please upload"
          );
        }

        if (this.commencementDocUrl && this.commencementDocUrl.length < 1) {
          msg.push(
            "We are expecting, Valid Australian Visa and Foreign Passport both required, Please upload"
          );
        }

        if (this.primary_doc_no != 10 && this.primary_doc_no != 14) {
          msg.push(
            "Please select Primary document as 'Current foreign passport with a valid entry stamp or visa'"
          );
        }
      }

      // Current Australian passport (not expired)
      if (this.commencement_form_no == 4) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc1)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1 == null) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1) {
          if (this.step5.doc1.passport_type == "") {
            msg.push("Passport type is required");
          }
          if (this.step5.doc1.country == "") {
            msg.push("Country is required");
          }
          if (this.step5.doc1.number == "") {
            msg.push("Number is required");
          }
          if (this.step5.doc1.family_name == "") {
            msg.push("Family name is required");
          }
        }

        if (
          (this.commencementDocUrl && this.commencementDocUrl.length == 0) ||
          this.commencementDocUrl == "" ||
          this.commencementDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      //
      if (this.commencement_form_no == 5) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc1)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1 == null) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1) {
          if (this.step5.doc1.issuing_agency == "") {
            msg.push("Issuing Agency is required");
          }
          if (this.step5.doc1.family_name == "") {
            msg.push("Family name is required");
          }
          if (this.step5.doc1.licence_no == "") {
            msg.push("License no is required");
          }
        }

        if (
          (this.commencementDocUrl && this.commencementDocUrl.length == 0) ||
          this.commencementDocUrl == "" ||
          this.commencementDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      //
      if (this.commencement_form_no == 6) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc1)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1 == null) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1) {
          if (this.step5.doc1.passport_type == "") {
            msg.push("Passport type is required");
          }
          if (this.step5.doc1.country == "") {
            msg.push("Country is required");
          }
          if (this.step5.doc1.number == "") {
            msg.push("Number is required");
          }
          if (this.step5.doc1.family_name == "") {
            msg.push("Family name is required");
          }
        }

        if (
          (this.commencementDocUrl && this.commencementDocUrl.length == 0) ||
          this.commencementDocUrl == "" ||
          this.commencementDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      //
      if (this.commencement_form_no == 7) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc1)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1 == null) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1) {
          if (this.step5.doc1.card_type == "") {
            msg.push("Card type is required");
          }
          if (this.step5.doc1.reference_no == "") {
            msg.push("Medicare no is required");
          }
          if (this.step5.doc1.individual_reference_no == "") {
            msg.push("Individual reference_no is required");
          }
          if (this.step5.doc1.name_per_document == "") {
            msg.push("Name per document is required");
          }
          if (this.step5.doc1.expiry_date == "") {
            msg.push("Expiry date is required");
          }
        }

        if (
          (this.commencementDocUrl && this.commencementDocUrl.length == 0) ||
          this.commencementDocUrl == "" ||
          this.commencementDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // I don’t have this document
      if (this.commencement_form_no == 8) {
        msg = [];
        if (this.isEmptyObject(this.step5.doc1)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1 == null) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1) {
          if (this.step5.doc1.cannot_provide_document == "") {
            msg.push("Reason is required");
          }
        }
      }

      // Australian Birth Certificate
      if (this.commencement_form_no == 9) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc1)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1 == null) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc1) {
          if (this.step5.doc1.state == "") {
            msg.push("State is required");
          }
          if (this.step5.doc1.family_name == "") {
            msg.push("Family name is required");
          }
          if (this.step5.doc1.reference_no == "") {
            msg.push("Registration number is required");
          }
          if (this.step5.doc1.date_certificate_issued == "") {
            msg.push("Date Certificate Issued is required");
          }
        }

        if (
          (this.commencementDocUrl && this.commencementDocUrl.length == 0) ||
          this.commencementDocUrl == "" ||
          this.commencementDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      if (this.commencement_doc_no == 4 && this.primary_doc_no == 6) {
        msg = [];
        msg.push(
          "Do not provide same document type in Commencement and Primary"
        );
      }

      if (this.commencement_doc_no == 3 && this.primary_doc_no == 8) {
        msg = [];
        msg.push(
          "Do not provide same document type in Commencement and Primary"
        );
      }

      return msg;
    },

    fnCheckPrimaryValidation() {
      // Relevant form for Primary 2,4,5,6,8
      let msg = [];

      // Check form has empty or not
      if (this.primary_form_no == null || this.primary_form_no == "") {
        msg.push(
          "Please select Identity document and provide relevant information and upload document/s"
        );
      }

      // form no - 1
      if (this.primary_form_no == 1) {
        if (
          (this.primaryDocUrl && this.primaryDocUrl.length == 0) ||
          this.primaryDocUrl == "" ||
          this.primaryDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // form no - 2
      if (this.primary_form_no == 2) {
        if (this.isEmptyObject(this.step5.doc2)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc2) {
          if (this.step5.doc2.reference_no == "") {
            msg.push("Reference No is required");
          }
        }

        if (this.step5.doc2 == null) {
          msg.push("Relevant information is required");
        }

        if (
          (this.primaryDocUrl && this.primaryDocUrl.length == 0) ||
          this.primaryDocUrl == "" ||
          this.primaryDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // form no - 3
      if (this.primary_form_no == 3) {
        msg = [];
        if (this.isEmptyObject(this.step5.doc2)) {
          msg.push("Relevant information is required");
        }
        if (this.step5.doc2 == null) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc2) {
          if (this.step5.doc2.family_name == "") {
            msg.push("Family Name is required");
          }
          if (this.step5.doc2.passport_no == "") {
            msg.push("Passport no is required");
          }
          if (this.step5.doc2.country == "") {
            msg.push("Country is required");
          }
        }

        if (
          (this.primaryDocUrl && this.primaryDocUrl.length == 0) ||
          this.primaryDocUrl == "" ||
          this.primaryDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // form no - 4
      if (this.primary_form_no == 4) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc2)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc2 == null) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc2) {
          if (this.step5.doc2.passport_type == "") {
            msg.push("Passport type is required");
          }
          if (this.step5.doc2.country == "") {
            msg.push("Country is required");
          }
          if (this.step5.doc2.number == "") {
            msg.push("Number is required");
          }
          if (this.step5.doc2.family_name == "") {
            msg.push("Family name is required");
          }
        }

        if (
          (this.primaryDocUrl && this.primaryDocUrl.length == 0) ||
          this.primaryDocUrl == "" ||
          this.primaryDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // form no - 5
      if (this.primary_form_no == 5) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc2)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc2 == null) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc2) {
          if (this.step5.doc2.issuing_agency == "") {
            msg.push("Issuing Agency is required");
          }
          if (this.step5.doc2.family_name == "") {
            msg.push("Family name is required");
          }
          if (this.step5.doc2.licence_no == "") {
            msg.push("License no is required");
          }
        }

        if (
          (this.primaryDocUrl && this.primaryDocUrl.length == 0) ||
          this.primaryDocUrl == "" ||
          this.primaryDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // Form no - 6
      if (this.primary_form_no == 6) {
        if (this.isEmptyObject(this.step5.doc2)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc2 == null) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc2) {
          if (this.step5.doc2.passport_type == "") {
            msg.push("Passport type is required");
          }
          if (this.step5.doc2.country == "") {
            msg.push("Country is required");
          }
          if (this.step5.doc2.number == "") {
            msg.push("Number is required");
          }
          if (this.step5.doc2.family_name == "") {
            msg.push("Family name is required");
          }
        }

        if (
          (this.primaryDocUrl && this.primaryDocUrl.length == 0) ||
          this.primaryDocUrl == "" ||
          this.primaryDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      if (
        this.primary_doc_no == 10 &&
        this.commencement_doc_no != 40 &&
        this.primary_doc_no == 10 &&
        this.commencement_doc_no != 5
      ) {
        msg.push(
          "Please select Commencement document as 'Valid Australian Visa (supported by a foreign passport)"
        );
      }

      if (this.primary_doc_no == 10 && this.commencement_doc_no == 40) {
        if (this.step5.doc1.reference_no != this.step5.doc2.number) {
          msg.push(
            "Passport number and Valid Australian Visa passport number do not match"
          );
        }
      }

      // form no - 7
      if (this.primary_form_no == 7) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc2)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc2 == null) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc2) {
          if (this.step5.doc2.card_type == "") {
            msg.push("Card type is required");
          }
          if (this.step5.doc2.reference_no == "") {
            msg.push("Medicare no is required");
          }
          if (this.step5.doc2.individual_reference_no == "") {
            msg.push("Individual reference_no is required");
          }
          if (this.step5.doc2.name_per_document == "") {
            msg.push("Name per document is required");
          }
          if (this.step5.doc2.expiry_date == "") {
            msg.push("Expiry date is required");
          }
        }

        if (
          (this.primaryDocUrl && this.primaryDocUrl.length == 0) ||
          this.primaryDocUrl == "" ||
          this.primaryDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // form no - 8
      if (this.primary_form_no == 8) {
        msg = [];
        if (this.isEmptyObject(this.step5.doc2)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc2 == null) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc2) {
          if (this.step5.doc2.cannot_provide_document == "") {
            msg.push("Reason is required");
          }
        }
      }

      // form no - 9
      if (this.primary_form_no == 9) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc2)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc2 == null) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc2) {
          if (this.step5.doc2.state == "") {
            msg.push("State is required");
          }
          if (this.step5.doc2.family_name == "") {
            msg.push("Family name is required");
          }
          if (this.step5.doc2.reference_no == "") {
            msg.push("Registration number is required");
          }
          if (this.step5.doc2.date_certificate_issued == "") {
            msg.push("Date Certificate Issued is required");
          }
        }

        if (
          (this.primaryDocUrl && this.primaryDocUrl.length == 0) ||
          this.primaryDocUrl == "" ||
          this.primaryDocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      if (this.commencement_doc_no == 4 && this.primary_doc_no == 6) {
        msg = [];
        msg.push(
          "Do not provide same document type in Commencement and Primary"
        );
      }

      if (this.commencement_doc_no == 3 && this.primary_doc_no == 8) {
        msg = [];
        msg.push(
          "Do not provide same document type in Commencement and Primary"
        );
      }

      return msg;
    },

    fnCheckSecondary1Validation() {
      // Relevant form for Primary 1,2,7

      let msg = [];

      // Check form has empty or not
      if (this.secondary1_form_no == null || this.secondary1_form_no == "") {
        msg.push(
          "Please select Identity document and provide relevant information and upload document/s"
        );
      }

      // form no - 1
      if (this.secondary1_form_no == 1) {
        if (this.secondary1DocUrl == null) {
          msg.push("Relevant document is required, Please upload");
        }
        if (
          (this.secondary1DocUrl && this.secondary1DocUrl.length == 0) ||
          this.secondary1DocUrl == ""
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // form no - 2
      if (this.secondary1_form_no == 2) {
        if (this.isEmptyObject(this.step5.doc3)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc3) {
          if (this.step5.doc3.reference_no == "") {
            msg.push("Reference No is required");
          }
        }

        if (this.step5.doc3 == null) {
          msg.push("Relevant document is required, Please upload");
        }

        if (
          (this.secondary1DocUrl && this.secondary1DocUrl.length == 0) ||
          this.secondary1DocUrl == "" ||
          this.secondary1DocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      //
      if (this.secondary1_form_no == 3) {
        msg = [];
        if (this.isEmptyObject(this.step5.doc3)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc3) {
          if (this.step5.doc3.family_name == "") {
            msg.push("Family Name is required");
          }
          if (this.step5.doc3.passport_no == "") {
            msg.push("Passport no is required");
          }
          if (this.step5.doc3.country == "") {
            msg.push("Country is required");
          }
        }

        if (this.secondary1DocUrl == null) {
          msg.push("Relevant document is required, Please upload");
        }

        if (
          (this.secondary1DocUrl && this.secondary1DocUrl.length == 0) ||
          this.secondary1DocUrl == ""
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // form no - 4
      if (this.secondary1_form_no == 4) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc3)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc3) {
          if (this.step5.doc3.passport_type == "") {
            msg.push("Passport type is required");
          }
          if (this.step5.doc3.country == "") {
            msg.push("Country is required");
          }
          if (this.step5.doc3.number == "") {
            msg.push("Number is required");
          }
          if (this.step5.doc3.family_name == "") {
            msg.push("Family name is required");
          }
        }

        if (this.secondary1DocUrl == null) {
          msg.push("Relevant document is required, Please upload");
        }
        if (
          (this.secondary1DocUrl && this.secondary1DocUrl.length == 0) ||
          this.secondary1DocUrl == "" ||
          this.secondary1DocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // form no - 5
      if (this.secondary1_form_no == 5) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc3)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc3) {
          if (this.step5.doc3.issuing_agency == "") {
            msg.push("Issuing Agency is required");
          }
          if (this.step5.doc3.family_name == "") {
            msg.push("Family name is required");
          }
          if (this.step5.doc3.licence_no == "") {
            msg.push("License no is required");
          }
        }

        if (
          (this.secondary1DocUrl && this.secondary1DocUrl.length == 0) ||
          this.secondary1DocUrl == "" ||
          this.secondary1DocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // Form no - 6
      if (this.secondary1_form_no == 6) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc3)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc3) {
          if (this.step5.doc3.passport_type == "") {
            msg.push("Passport type is required");
          }
          if (this.step5.doc3.country == "") {
            msg.push("Country is required");
          }
          if (this.step5.doc3.number == "") {
            msg.push("Number is required");
          }
          if (this.step5.doc3.family_name == "") {
            msg.push("Family name is required");
          }
        }

        if (
          (this.secondary1DocUrl && this.secondary1DocUrl.length == 0) ||
          this.secondary1DocUrl == ""
        ) {
          msg.push("Relevant document is required, Please upload");
        }

        if (this.secondary1DocUrl == null) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      //
      if (this.secondary1_form_no == 7) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc3)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc3) {
          if (this.step5.doc3.card_type == "") {
            msg.push("Card type is required");
          }
          if (this.step5.doc3.reference_no == "") {
            msg.push("Medicare no is required");
          }
          if (this.step5.doc3.individual_reference_no == "") {
            msg.push("Individual reference_no is required");
          }
          if (this.step5.doc3.name_per_document == "") {
            msg.push("Name per document is required");
          }
          if (this.step5.doc3.expiry_date == "") {
            msg.push("Expiry date is required");
          }
        }

        if (this.secondary1DocUrl == null) {
          msg.push("Relevant document is required, Please upload");
        }

        if (
          (this.secondary1DocUrl && this.secondary1DocUrl.length == 0) ||
          this.secondary1DocUrl == ""
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // form no - 8
      if (this.secondary1_form_no == 8) {
        msg = [];
        if (this.isEmptyObject(this.step5.doc3)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc3) {
          if (this.step5.doc3.cannot_provide_document == "") {
            msg.push("Reason is required");
          }
        }
      }

      // form no - 9
      if (this.secondary1_form_no == 9) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc3)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc3) {
          if (this.step5.doc3.state == "") {
            msg.push("State is required");
          }
          if (this.step5.doc3.family_name == "") {
            msg.push("Family name is required");
          }
          if (this.step5.doc3.reference_no == "") {
            msg.push("Registration number is required");
          }
          if (this.step5.doc3.date_certificate_issued == "") {
            msg.push("Date Certificate Issued is required");
          }
        }

        if (this.secondary1DocUrl == null) {
          msg.push("Relevant document is required, Please upload");
        }

        if (
          (this.secondary1DocUrl && this.secondary1DocUrl.length == 0) ||
          this.secondary1DocUrl == ""
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      if (
        this.secondary1_doc_no != "" &&
        this.secondary1_doc_no &&
        this.secondary2_doc_no != "" &&
        this.secondary2_doc_no &&
        this.secondary1_doc_no == this.secondary2_doc_no
      ) {
        msg = [];
        msg.push(
          "Do not provide same document type in Secondary 1 and Secondary 2"
        );
      }

      return msg;
    },

    fnCheckSecondary2Validation() {
      // Relevant form for Primary 1,2,7

      let msg = [];

      // Check form has empty or not
      if (this.secondary2_form_no == null || this.secondary2_form_no == "") {
        msg.push(
          "Please select Identity document and provide relevant information and upload document/s"
        );
      }

      // form no - 1
      if (this.secondary2_form_no == 1) {
        if (this.secondary2DocUrl == null) {
          msg.push("Relevant document is required, Please upload");
        }
        if (
          (this.secondary2DocUrl && this.secondary2DocUrl.length == 0) ||
          this.secondary2DocUrl == ""
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // form no - 2
      if (this.secondary2_form_no == 2) {
        if (this.isEmptyObject(this.step5.doc4)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc4) {
          if (this.step5.doc4.reference_no == "") {
            msg.push("Reference No is required");
          }
        }

        if (this.step5.doc4 == null) {
          msg.push("Relevant document is required, Please upload");
        }

        if (
          (this.secondary2DocUrl && this.secondary2DocUrl.length == 0) ||
          this.secondary2DocUrl == "" ||
          this.secondary2DocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      //
      if (this.secondary2_form_no == 3) {
        msg = [];
        if (this.isEmptyObject(this.step5.doc4)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc4) {
          if (this.step5.doc4.family_name == "") {
            msg.push("Family Name is required");
          }
          if (this.step5.doc4.passport_no == "") {
            msg.push("Passport no is required");
          }
          if (this.step5.doc4.country == "") {
            msg.push("Country is required");
          }
        }

        if (this.secondary2DocUrl == null) {
          msg.push("Relevant document is required, Please upload");
        }

        if (
          (this.secondary2DocUrl && this.secondary2DocUrl.length == 0) ||
          this.secondary2DocUrl == ""
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // form no - 4
      if (this.secondary2_form_no == 4) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc4)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc4) {
          if (this.step5.doc4.passport_type == "") {
            msg.push("Passport type is required");
          }
          if (this.step5.doc4.country == "") {
            msg.push("Country is required");
          }
          if (this.step5.doc4.number == "") {
            msg.push("Number is required");
          }
          if (this.step5.doc4.family_name == "") {
            msg.push("Family name is required");
          }
        }

        if (this.secondary2DocUrl == null) {
          msg.push("Relevant document is required, Please upload");
        }
        if (
          (this.secondary2DocUrl && this.secondary2DocUrl.length == 0) ||
          this.secondary2DocUrl == "" ||
          this.secondary2DocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // form no - 5
      if (this.secondary2_form_no == 5) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc4)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc4) {
          if (this.step5.doc4.issuing_agency == "") {
            msg.push("Issuing Agency is required");
          }
          if (this.step5.doc4.family_name == "") {
            msg.push("Family name is required");
          }
          if (this.step5.doc4.licence_no == "") {
            msg.push("License no is required");
          }
        }

        if (
          (this.secondary2DocUrl && this.secondary2DocUrl.length == 0) ||
          this.secondary2DocUrl == "" ||
          this.secondary2DocUrl == null
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // Form no - 6
      if (this.secondary2_form_no == 6) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc4)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc4) {
          if (this.step5.doc4.passport_type == "") {
            msg.push("Passport type is required");
          }
          if (this.step5.doc4.country == "") {
            msg.push("Country is required");
          }
          if (this.step5.doc4.number == "") {
            msg.push("Number is required");
          }
          if (this.step5.doc4.family_name == "") {
            msg.push("Family name is required");
          }
        }

        if (
          (this.secondary2DocUrl && this.secondary2DocUrl.length == 0) ||
          this.secondary2DocUrl == ""
        ) {
          msg.push("Relevant document is required, Please upload");
        }

        if (this.secondary2DocUrl == null) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      //
      if (this.secondary2_form_no == 7) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc4)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc4) {
          if (this.step5.doc4.card_type == "") {
            msg.push("Card type is required");
          }
          if (this.step5.doc4.reference_no == "") {
            msg.push("Medicare no is required");
          }
          if (this.step5.doc4.individual_reference_no == "") {
            msg.push("Individual reference_no is required");
          }
          if (this.step5.doc4.name_per_document == "") {
            msg.push("Name per document is required");
          }
          if (this.step5.doc4.expiry_date == "") {
            msg.push("Expiry date is required");
          }
        }

        if (this.secondary2DocUrl == null) {
          msg.push("Relevant document is required, Please upload");
        }

        if (
          (this.secondary2DocUrl && this.secondary2DocUrl.length == 0) ||
          this.secondary2DocUrl == ""
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      // form no - 8
      if (this.secondary2_form_no == 8) {
        msg = [];
        if (this.isEmptyObject(this.step5.doc4)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc4) {
          if (this.step5.doc4.cannot_provide_document == "") {
            msg.push("Reason is required");
          }
        }
      }

      // form no - 9
      if (this.secondary2_form_no == 9) {
        msg = [];

        if (this.isEmptyObject(this.step5.doc4)) {
          msg.push("Relevant information is required");
        }

        if (this.step5.doc4) {
          if (this.step5.doc4.state == "") {
            msg.push("State is required");
          }
          if (this.step5.doc4.family_name == "") {
            msg.push("Family name is required");
          }
          if (this.step5.doc4.reference_no == "") {
            msg.push("Registration number is required");
          }
          if (this.step5.doc4.date_certificate_issued == "") {
            msg.push("Date Certificate Issued is required");
          }
        }

        if (this.secondary2DocUrl == null) {
          msg.push("Relevant document is required, Please upload");
        }

        if (
          (this.secondary2DocUrl && this.secondary2DocUrl.length == 0) ||
          this.secondary2DocUrl == ""
        ) {
          msg.push("Relevant document is required, Please upload");
        }
      }

      if (
        this.secondary1_doc_no != "" &&
        this.secondary1_doc_no &&
        this.secondary2_doc_no != "" &&
        this.secondary2_doc_no &&
        this.secondary1_doc_no == this.secondary2_doc_no
      ) {
        msg = [];
        msg.push(
          "Do not provide same document type in Secondary 1 and Secondary 2"
        );
      }

      return msg;
    },

    fnCheckOtherValidation() {
      let msg = [];

      if (this.identity_documents_same_name == "") {
        msg.push(
          "Please confirm if your identity documents are all in the same name"
        );
      }

      if (
        this.identity_documents_same_name == "No" &&
        this.identity_documents_same_name_urls == null
      ) {
        msg.push("Please upload a Change of Name document");
      }

      if (
        this.identity_documents_same_name == "No" &&
        this.identity_documents_same_name_urls == ""
      ) {
        msg.push("Please upload a Change of Name document");
      }

      if (this.identity_documents_same_name_urls) {
        if (
          this.identity_documents_same_name == "No" &&
          this.identity_documents_same_name_urls.length == 0
        ) {
          msg.push("Please upload a Change of Name document");
        }
      }

      return msg;
    },

    fnIdentityDocumentsSameNameChange() {
      this.identityDocumentsSameNameValidation = [];
    },
    isEmptyObject(value) {
      return Object.keys(value).length == 0 && value.constructor == Object;
    },
  },
  created() {
    this.fetchPoliceCheckSingle(this.policeCheckId);
  },
  mounted() {
    if (this.getPoliceCheckSingle.upload_identity) {
      this.commencement_doc_no =
        this.getPoliceCheckSingle.upload_identity.commencement_doc_no;
      this.commencement_doc_name =
        this.getPoliceCheckSingle.upload_identity.commencement_doc_name;
      this.commencement_doc_nss_name =
        this.getPoliceCheckSingle.upload_identity.commencement_doc_nss_name;
      this.commencement_form_no =
        this.getPoliceCheckSingle.upload_identity.commencement_form_no;
      this.primary_doc_no =
        this.getPoliceCheckSingle.upload_identity.primary_doc_no;
      this.primary_doc_name =
        this.getPoliceCheckSingle.upload_identity.primary_doc_name;
      this.primary_doc_nss_name =
        this.getPoliceCheckSingle.upload_identity.primary_doc_nss_name;
      this.primary_form_no =
        this.getPoliceCheckSingle.upload_identity.primary_form_no;
      this.secondary1_doc_no =
        this.getPoliceCheckSingle.upload_identity.secondary1_doc_no;
      this.secondary1_doc_name =
        this.getPoliceCheckSingle.upload_identity.secondary1_doc_name;
      this.secondary1_doc_nss_name =
        this.getPoliceCheckSingle.upload_identity.secondary1_doc_nss_name;
      this.secondary1_form_no =
        this.getPoliceCheckSingle.upload_identity.secondary1_form_no;
      this.secondary2_doc_no =
        this.getPoliceCheckSingle.upload_identity.secondary2_doc_no;
      this.secondary2_doc_name =
        this.getPoliceCheckSingle.upload_identity.secondary2_doc_name;
      this.secondary2_doc_nss_name =
        this.getPoliceCheckSingle.upload_identity.secondary2_doc_nss_name;
      this.secondary2_form_no =
        this.getPoliceCheckSingle.upload_identity.secondary2_form_no;

      if (this.getPoliceCheckSingle.upload_identity) {
        this.step5 = this.getPoliceCheckSingle.upload_identity.step;
      }

      this.identity_documents_same_name =
        this.getPoliceCheckSingle.identity_documents_same_name;
    }
  },
  watch: {
    getPoliceCheckSingle(val) {
      this.commencementDocUrl = val.commencement_doc_urls;
      this.primaryDocUrl = val.primary_doc_urls;
      this.secondary1DocUrl = val.secondary1_doc_urls;
      this.secondary2DocUrl = val.secondary2_doc_urls;
      this.identity_documents_same_name_urls =
        val.identity_documents_same_name_urls;
    },

    commencement_form_no(val) {
      this.commencementValidation = [];
      this.primaryValidation = [];
      this.secondary1Validation = [];
      this.secondary2Validation = [];
      this.identityDocumentsSameNameValidation = [];

      if (this.commencement_doc_no == 40) {
        if (this.primary_doc_no != 10 && this.primary_doc_no != 5) {
          this.primaryValidation.push(
            "Please select primary document as 'Current foreign passport with a valid entry stamp or visa"
          );
        }
      }
    },
    primary_form_no(val) {
      this.commencementValidation = [];
      this.primaryValidation = [];
      this.secondary1Validation = [];
      this.secondary2Validation = [];
      this.identityDocumentsSameNameValidation = [];
    },
    secondary1_form_no() {
      this.commencementValidation = [];
      this.primaryValidation = [];
      this.secondary1Validation = [];
      this.secondary2Validation = [];
      this.identityDocumentsSameNameValidation = [];
    },
    secondary2_form_no() {
      this.commencementValidation = [];
      this.primaryValidation = [];
      this.secondary1Validation = [];
      this.secondary2Validation = [];
      this.identityDocumentsSameNameValidation = [];
    },
  },
};
</script>
