<template>
  <div class="card sits-border-info">
    <div class="card-header">
      <h5 class="text-info mb-0">Check Details</h5>
    </div>
    <div class="card-body">
      <dl class="row" v-if="propsData && propsData.product">
        <dd class="col-sm-5 fw-700">Ref. no</dd>
        <dd class="col-sm-7 fw-700">{{ propsData.id }}</dd>
        <dd class="col-sm-5 fw-700">Type</dd>
        <dd class="col-sm-7">{{ propsData.product.name }}</dd>
        <dd class="col-sm-5 fw-700">Fee</dd>
        <dd class="col-sm-7">{{ propsData.fee }}</dd>
        <dd class="col-sm-5 fw-700">Category</dd>
        <dd class="col-sm-7">{{ propsData.category }}</dd>
        <dd class="col-sm-5 fw-700">Position title</dd>
        <dd class="col-sm-7">{{ propsData.position_title }}</dd>
        <dd class="col-sm-5 fw-700">Licence type sought</dd>
        <dd class="col-sm-7">{{ propsData.licence_type_sought }}</dd>
        <dd class="col-sm-5 fw-700">Check purpose</dd>
        <dd class="col-sm-7">{{ propsData.check_purpose }}</dd>
        <dd class="col-sm-5 fw-700">Place of work</dd>
        <dd class="col-sm-7">{{ propsData.place_of_work }}</dd>
        <dd class="col-sm-5 fw-700">Issuing authority</dd>
        <dd class="col-sm-7">{{ propsData.issuing_authority }}</dd>
        <dd class="col-sm-5 fw-700">Requesting organisation</dd>
        <dd class="col-sm-7">{{ propsData.requesting_organisation }}</dd>
        <dd class="col-sm-5 fw-700">Location town</dd>
        <dd class="col-sm-7">{{ propsData.location_town }}</dd>
        <dd class="col-sm-5 fw-700" v-if="propsData.state_code">State</dd>
        <dd class="col-sm-7" v-if="propsData.state_code">
          {{ propsData.state_code }}
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
export default {
  name: "pcCheckDetailsCom",
  props: {
    propsData: {
      required: true,
      type: Object,
    },
  },
};
</script>
        
    