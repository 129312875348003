<template>
  <div class="card sits-border-info">
    <div class="card-header">
      <h5 class="text-info mb-0">Upload Identity Documents</h5>
    </div>

    <div class="card-body">
      <div v-if="propsData.upload_identity">
        <dl class="row">
          <dt class="col-sm-4">Commencement</dt>
          <dd class="col-sm-8">
            <div>{{ propsData.upload_identity.commencement_doc_name }}</div>
            <div class="text-danger mb-3 fs-600 fw-400">
              {{ propsData.upload_identity.commencement_doc_nss_name }}
            </div>
            <!-- 2 -->
            <appWDfrmDis02
              v-if="propsData.upload_identity.commencement_form_no == 2"
              :propsData="propsData.upload_identity.step.doc1"
            >
            </appWDfrmDis02>
            <!-- 3 -->
            <appWDfrmDis03
              v-if="propsData.upload_identity.commencement_form_no == 3"
              :propsData="propsData.upload_identity.step.doc1"
            >
            </appWDfrmDis03>

            <!-- 4 -->
            <appWDfrmDis04
              v-if="propsData.upload_identity.commencement_form_no == 4"
              :propsData="propsData.upload_identity.step.doc1"
            >
            </appWDfrmDis04>

            <!-- 5 -->
            <appWDfrmDis05
              v-if="propsData.upload_identity.commencement_form_no == 5"
              :propsData="propsData.upload_identity.step.doc1"
            >
            </appWDfrmDis05>

            <!-- 6 -->
            <appWDfrmDis06
              v-if="propsData.upload_identity.commencement_form_no == 6"
              :propsData="propsData.upload_identity.step.doc1"
            >
            </appWDfrmDis06>

            <!-- 7 -->
            <appWDfrmDis07
              v-if="propsData.upload_identity.commencement_form_no == 7"
              :propsData="propsData.upload_identity.step.doc1"
            >
            </appWDfrmDis07>

            <!-- 8 -->
            <appWDfrmDis08
              v-if="propsData.upload_identity.commencement_form_no == 8"
              :propsData="propsData.upload_identity.step.doc1"
            >
            </appWDfrmDis08>

            <!-- 9 -->
            <appWDfrmDis09
              v-if="propsData.upload_identity.commencement_form_no == 9"
              :propsData="propsData.upload_identity.step.doc1"
            >
            </appWDfrmDis09>

            <div class="repeatable-images">
              <wdImageAvatarPreview
                v-for="(doc, index) in propsData.commencement_doc_urls"
                :key="index"
                :title="'Commencement document'"
                :coverImgUrl="doc"
                :fontAwClass="'fas fa-file-alt'"
              ></wdImageAvatarPreview>
            </div>
            <hr />
          </dd>
          <dt class="col-sm-4">Primary</dt>
          <dd class="col-sm-8">
            <div>{{ propsData.upload_identity.primary_doc_name }}</div>
            <div class="text-danger mb-3 fs-600 fw-400">
              {{ propsData.upload_identity.primary_doc_nss_name }}
            </div>
            <!-- 2 -->
            <appWDfrmDis02
              v-if="propsData.upload_identity.primary_form_no == 2"
              :propsData="propsData.upload_identity.step.doc2"
            >
            </appWDfrmDis02>
            <!-- 3 -->
            <appWDfrmDis03
              v-if="propsData.upload_identity.primary_form_no == 3"
              :propsData="propsData.upload_identity.step.doc2"
            >
            </appWDfrmDis03>

            <!-- 4 -->
            <appWDfrmDis04
              v-if="propsData.upload_identity.primary_form_no == 4"
              :propsData="propsData.upload_identity.step.doc2"
            >
            </appWDfrmDis04>

            <!-- 5 -->
            <appWDfrmDis05
              v-if="propsData.upload_identity.primary_form_no == 5"
              :propsData="propsData.upload_identity.step.doc2"
            >
            </appWDfrmDis05>

            <!-- 6 -->
            <appWDfrmDis06
              v-if="propsData.upload_identity.primary_form_no == 6"
              :propsData="propsData.upload_identity.step.doc2"
            >
            </appWDfrmDis06>

            <!-- 7 -->
            <appWDfrmDis07
              v-if="propsData.upload_identity.primary_form_no == 7"
              :propsData="propsData.upload_identity.step.doc2"
            >
            </appWDfrmDis07>

            <!-- 8 -->
            <appWDfrmDis08
              v-if="propsData.upload_identity.primary_form_no == 8"
              :propsData="propsData.upload_identity.step.doc2"
            >
            </appWDfrmDis08>

            <!-- 9 -->
            <appWDfrmDis09
              v-if="propsData.upload_identity.primary_form_no == 9"
              :propsData="propsData.upload_identity.step.doc2"
            >
            </appWDfrmDis09>

            <div class="repeatable-images">
              <wdImageAvatarPreview
                v-for="(doc, index) in propsData.primary_doc_urls"
                :key="index"
                :title="'Primary document'"
                :coverImgUrl="doc"
                :fontAwClass="'fas fa-file-alt'"
              ></wdImageAvatarPreview>
            </div>
            <hr />
          </dd>
          <dt class="col-sm-4">Secondary - 1</dt>
          <dd class="col-sm-8">
            <div>{{ propsData.upload_identity.secondary1_doc_name }}</div>
            <div class="text-danger mb-3 fs-600 fw-400">
              {{ propsData.upload_identity.secondary1_doc_nss_name }}
            </div>
            <!-- 2 -->
            <appWDfrmDis02
              v-if="propsData.upload_identity.secondary1_form_no == 2"
              :propsData="propsData.upload_identity.step.doc3"
            >
            </appWDfrmDis02>
            <!-- 3 -->
            <appWDfrmDis03
              v-if="propsData.upload_identity.secondary1_form_no == 3"
              :propsData="propsData.upload_identity.step.doc3"
            >
            </appWDfrmDis03>

            <!-- 4 -->
            <appWDfrmDis04
              v-if="propsData.upload_identity.secondary1_form_no == 4"
              :propsData="propsData.upload_identity.step.doc3"
            >
            </appWDfrmDis04>

            <!-- 5 -->
            <appWDfrmDis05
              v-if="propsData.upload_identity.secondary1_form_no == 5"
              :propsData="propsData.upload_identity.step.doc3"
            >
            </appWDfrmDis05>

            <!-- 6 -->
            <appWDfrmDis06
              v-if="propsData.upload_identity.secondary1_form_no == 6"
              :propsData="propsData.upload_identity.step.doc3"
            >
            </appWDfrmDis06>

            <!-- 7 -->
            <appWDfrmDis07
              v-if="propsData.upload_identity.secondary1_form_no == 7"
              :propsData="propsData.upload_identity.step.doc3"
            >
            </appWDfrmDis07>

            <!-- 8 -->
            <appWDfrmDis08
              v-if="propsData.upload_identity.secondary1_form_no == 8"
              :propsData="propsData.upload_identity.step.doc3"
            >
            </appWDfrmDis08>

            <!-- 9 -->
            <appWDfrmDis09
              v-if="propsData.upload_identity.secondary1_form_no == 9"
              :propsData="propsData.upload_identity.step.doc3"
            >
            </appWDfrmDis09>

            <div class="repeatable-images">
              <wdImageAvatarPreview
                v-for="(doc, index) in propsData.secondary1_doc_urls"
                :key="index"
                :title="'Secondary document - 1'"
                :coverImgUrl="doc"
                :fontAwClass="'fas fa-file-alt'"
              ></wdImageAvatarPreview>
            </div>
            <hr />
          </dd>
          <dt class="col-sm-4">Secondary - 2</dt>
          <dd class="col-sm-8">
            <div>{{ propsData.upload_identity.secondary2_doc_name }}</div>
            <div class="text-danger mb-3 fs-600 fw-400">
              {{ propsData.upload_identity.secondary2_doc_nss_name }}
            </div>
            <!-- 2 -->
            <appWDfrmDis02
              v-if="propsData.upload_identity.secondary2_form_no == 2"
              :propsData="propsData.upload_identity.step.doc4"
            >
            </appWDfrmDis02>
            <!-- 3 -->
            <appWDfrmDis03
              v-if="propsData.upload_identity.secondary2_form_no == 3"
              :propsData="propsData.upload_identity.step.doc4"
            >
            </appWDfrmDis03>

            <!-- 4 -->
            <appWDfrmDis04
              v-if="propsData.upload_identity.secondary2_form_no == 4"
              :propsData="propsData.upload_identity.step.doc4"
            >
            </appWDfrmDis04>

            <!-- 5 -->
            <appWDfrmDis05
              v-if="propsData.upload_identity.secondary2_form_no == 5"
              :propsData="propsData.upload_identity.step.doc4"
            >
            </appWDfrmDis05>

            <!-- 6 -->
            <appWDfrmDis06
              v-if="propsData.upload_identity.secondary2_form_no == 6"
              :propsData="propsData.upload_identity.step.doc4"
            >
            </appWDfrmDis06>

            <!-- 7 -->
            <appWDfrmDis07
              v-if="propsData.upload_identity.secondary2_form_no == 7"
              :propsData="propsData.upload_identity.step.doc4"
            >
            </appWDfrmDis07>

            <!-- 8 -->
            <appWDfrmDis08
              v-if="propsData.upload_identity.secondary2_form_no == 8"
              :propsData="propsData.upload_identity.step.doc4"
            >
            </appWDfrmDis08>

            <!-- 9 -->
            <appWDfrmDis09
              v-if="propsData.upload_identity.secondary2_form_no == 9"
              :propsData="propsData.upload_identity.step.doc1"
            >
            </appWDfrmDis09>

            <div class="repeatable-images">
              <wdImageAvatarPreview
                v-for="(doc, index) in propsData.secondary2_doc_urls"
                :key="index"
                :title="'Secondary document - 2'"
                :coverImgUrl="doc"
                :fontAwClass="'fas fa-file-alt'"
              ></wdImageAvatarPreview>
            </div>
            <hr />
          </dd>

          <dt class="col-sm-4">
            My identity documents are all in the same name (Yes/No)
          </dt>
          <dd class="col-sm-8">
            <div>{{ propsData.identity_documents_same_name }}</div>

            <div class="repeatable-images">
              <wdImageAvatarPreview
                v-for="(
                  doc, index
                ) in propsData.identity_documents_same_name_urls"
                :key="index"
                :title="'Identity documents'"
                :coverImgUrl="doc"
                :fontAwClass="'fas fa-file-alt'"
              ></wdImageAvatarPreview>
            </div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
import appWDfrmDis02 from "../../components/policeCheck/WDfrmDis02.vue";
import appWDfrmDis03 from "../../components/policeCheck/WDfrmDis03.vue";
import appWDfrmDis04 from "../../components/policeCheck/WDfrmDis04.vue";
import appWDfrmDis05 from "../../components/policeCheck/WDfrmDis05.vue";
import appWDfrmDis06 from "../../components/policeCheck/WDfrmDis06.vue";
import appWDfrmDis07 from "../../components/policeCheck/WDfrmDis07.vue";
import appWDfrmDis08 from "../../components/policeCheck/WDfrmDis08.vue";
import appWDfrmDis09 from "../../components/policeCheck/WDfrmDis09.vue";

import wdImageAvatarPreview from "../../components/wd/WDImageAvatarPreview.vue";

export default {
  name: "pcUploadIdentityDocumentsCom",
  props: {
    propsData: {
      required: true,
      type: Object,
    },
  },
  components: {
    appWDfrmDis02,
    appWDfrmDis03,
    appWDfrmDis04,
    appWDfrmDis05,
    appWDfrmDis06,
    appWDfrmDis07,
    appWDfrmDis08,
    appWDfrmDis09,
    wdImageAvatarPreview,
  },
};
</script>