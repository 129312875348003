<template>
    <div class="row">
        <div class="col-12">
            <h5>Identity Documents Instructions</h5>
            <b-alert variant="info" show>
                <p class="fw-600 lh1500">Identity Documents</p>
                <p>You are required to upload 4 identity documents as part of your application. The combination of your 4 documents must contain the following at a minimum:</p>
                <div class="d-flex flex-row mb-2">
                    <div class="pr-3">
                        ✓
                    </div>
                    <div class="">
                        1 document containing your photo.
                    </div>
                </div>
                <div class="d-flex flex-row mb-2">
                    <div class="pr-3">
                        ✓
                    </div>
                    <div class="">
                        1 document containing your date of birth.
                    </div>
                </div>
            </b-alert>
            <b-alert variant="danger" show>
                <p class="fw-600 lh1500">Change of Name</p>
                <p>If the identity documents that are uploaded are not all in the same name, then a change of name document will also need to be uploaded to verify the change of name. e.g., Marriage Certificate.</p>
            </b-alert>

            <b-alert variant="danger" show v-if="(commencementDocObj && commencementDocObj[0].form_type_id == 4)
                || (primaryDocObj && primaryDocObj[0].form_type_id == 4) 
                || (primaryDocObj && primaryDocObj[0].form_type_id == 6) 
                || (secondary1DocObj && secondary1DocObj[0].form_type_id == 4)
                || (secondary2DocObj && secondary2DocObj[0].form_type_id == 4)">
                <p class="fw-600">Passport Type</p>
                <ul>
                    <li>Private Passport: Issued to individuals for ordinary travel, such as vacations and business trips.</li>
                    <li>Government Passport: Issued to individuals representing their respective government on official government business. </li>
                </ul>
            </b-alert>
            <b-alert variant="warning" show v-if="(primaryDocObj && primaryDocObj[0].form_type_id == 6)">
                <p class="mb-0">Upload Valid Australian Visa</p>
            </b-alert>
        </div>
    </div>
</template>
<script>
export default {
    name:'',
    props:['dataProps', 'commencementDocObj', 'primaryDocObj', 'secondary1DocObj','secondary2DocObj'],
    data(){
        return{

        }
    }
}
</script>