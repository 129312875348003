<template>
  <div class="card sits-border-info">
    <div class="card-header">
      <h5 class="text-info mb-0">Consent</h5>
    </div>
    <div class="card-body" v-if="propsData && propsData.profile_info">
      <dl class="row">
        <dt class="col-sm-2">General</dt>
        <dd class="col-sm-10">
          <p>
            Our information services are provided in accordance with our
            standard Terms and Conditions accessible at
            www.cvscreen.com.au/termasandconditions.
          </p>
        </dd>

        <dt class="col-sm-2">I am over 18 years of age</dt>
        <dd class="col-sm-10 mt-3">
          <p>
            If you are less than 18 years of age, the National Police Check form
            must be co-signed by your parent or legal guardian
          </p>
          <div>
            <span v-if="propsData.yearsOfAge == 'O'"
              >I am over 18 years of age</span
            >
            <span v-if="propsData.yearsOfAge == 'U'"
              >I am under 18 years of age</span
            >
          </div>
        </dd>

        <dt class="col-sm-2">National Police Check</dt>
        <dd class="col-sm-10">
          I, {{ propsData.profile_info.first_name }}
          {{ propsData.profile_info.last_name }}, herby consent to CV Screen Pty
          Ltd using my personal details to conduct or procure the conduct of a
          National Police Check.
        </dd>
        <dt class="col-sm-2">Signature</dt>
        <dd class="col-sm-10 mt-3">
          <wdImage
            v-if="propsData.signature"
            :imgUrl="propsData.signature"
            :imgStyle="'height: 100px; width: auto'"
            :imgClass="'card-img-top'"
          ></wdImage>
        </dd>
        <dt v-if="propsData.yearsOfAge == 'U'" class="col-sm-2">
          Parent or Legal Guardian Signature
        </dt>
        <dd v-if="propsData.yearsOfAge == 'U'" class="col-sm-10 mt-3">
          <wdImage
            v-if="propsData.guardianSignature"
            :imgUrl="propsData.guardianSignature"
            :imgStyle="'height: 100px; width: auto'"
            :imgClass="'card-img-top'"
          ></wdImage>
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import wdImage from "../../components/wd/WDImage.vue";
export default {
  name: "reviewBlock2",
  props: {
    propsData: {
      required: true,
      type: Object,
    },
  },
  components: {
    wdImage,
  },
};
</script>