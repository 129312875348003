<template>
  <div class="card sits-border-info">
    <div class="card-header">
      <h5 class="text-info mb-0">Personal Details</h5>
    </div>

    <div class="card-body">
      <dl class="row" v-if="propsData && propsData.profile_info">
        <dd class="col-sm-5 fw-700">Gender</dd>
        <dd class="col-sm-7" v-if="propsData.profile_info.gender">
          {{ propsData.profile_info.gender.name }}
        </dd>
        <dd class="col-sm-5 fw-700">Given name</dd>
        <dd class="col-sm-7">{{ propsData.profile_info.first_name }}</dd>
        <dd class="col-sm-5 fw-700">Middle name</dd>
        <dd class="col-sm-7">{{ propsData.profile_info.middle_name }}</dd>
        <dd class="col-sm-5 fw-700">Family name</dd>
        <dd class="col-sm-7">{{ propsData.profile_info.last_name }}</dd>
        <dd class="col-sm-5 fw-700">Date of birth</dd>
        <dd class="col-sm-7">{{ propsData.profile_info.date_of_birth }}</dd>
        <dd class="col-sm-5 fw-700">Country of birth</dd>
        <dd class="col-sm-7">
          {{ propsData.profile_info.country_of_birth }}
        </dd>
        <dd class="col-sm-5 fw-700">Suburb of birth</dd>
        <dd class="col-sm-7">
          {{ propsData.profile_info.country_of_birth_suburb }}
        </dd>
        <dd class="col-sm-5 fw-700">State of birth</dd>
        <dd class="col-sm-7">
          {{ propsData.profile_info.country_of_birth_state }}
        </dd>
        <dd class="col-sm-5 fw-700">Email</dd>
        <dd class="col-sm-7">{{ propsData.profile_info.email }}</dd>
        <dd class="col-sm-5 fw-700">Mobile no</dd>
        <dd class="col-sm-7">{{ propsData.profile_info.mobile_no }}</dd>
        <dd class="col-sm-5 fw-700">I do not have a given name</dd>
        <dd class="col-sm-7">
          {{ propsData.profile_info.have_a_given_name }}
        </dd>
        <dd class="col-sm-5 fw-700">I do not have a middle name</dd>
        <dd class="col-sm-7">
          {{ propsData.profile_info.have_a_middle_name }}
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
export default {
  name: "pcPersonalDetailsCom",
  props: {
    propsData: {
      required: true,
      type: Object,
    },
  },
};
</script>