<template>
  <div>
    <a
      href="javascript: void(0)"
      data-toggle="tooltip"
      data-placement="top"
      @click="fnShowModal()"
      ><i class="far fa-edit text-info"></i
    ></a>

    <b-modal
      ref="previousAddressModal"
      size="xl"
      hide-footer
      title="Previous Address"
    >
      <div class="d-block">
        <div class="alert alert-danger mb-5 col-12" v-if="errorsMsg.length">
          <p
            class="fs-800 mb-0"
            v-for="(item, index) in errorsMsg"
            :key="index"
          >
            {{ item }}
          </p>
        </div>
        <form v-on:submit.prevent="updatePreviousAddress">
          <div class="row">
            <div class="col-lg-2 col-md-2 col-6">
              <div class="form-group">
                <label class="" for="type">Type</label>
                <select class="form-control" v-model="userData.address_type">
                  <option
                    :value="item.value"
                    v-for="(item, i) in optionsAddressType"
                    :key="i"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-6">
              <div class="form-group">
                <label for="firstName">Unit Number</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Unit Number"
                  v-model="userData.unit_number"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <label for="street_number">Street Number</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Street Number"
                  v-model="userData.street_number"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <label for="Street">Street</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Street"
                  v-model="userData.street"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <label class="">Suburb</label>
                <input
                  class="form-control"
                  type="text"
                  autocomplete="off_suburb"
                  placeholder="Suburb"
                  v-model="userData.suburb"
                />
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-6">
              <div class="form-group">
                <label class="" for="state">State</label>
                <select class="form-control" v-model="userData.state">
                  <option
                    :value="item.value"
                    v-for="(item, i) in optionsState"
                    :key="i"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-6">
              <div class="form-group">
                <label for="post_code">Post Code</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Post Code"
                  v-model="userData.post_code"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <label class="" for="country">Country</label>
                <select class="form-control" v-model="userData.country_code">
                  <option
                    :value="item.alpha_3"
                    v-for="(item, i) in getSettingAll.country"
                    :key="i"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <label for="from_date">From Date</label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="From Date"
                  v-model="userData.from_date"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <label for="to_date">To Date</label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="To Date"
                  v-model="userData.to_date"
                />
              </div>
            </div>
          </div>
          <div class="text-right">
            <button
              type="button"
              class="btn btn-secondary btn-sm mr-2"
              @click="hideModal"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-info btn-sm">Save</button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as policeCheckService from "../../services/policeCheck.service";

export default {
  name: "ModalPreviousAddressEdit",
  props: [
    "policeCheckId",
    "previous_address_status",
    "propsAddressData",
    "propsAddressIndex",
    "previous_address",
  ],
  components: {},
  data() {
    return {
      errors: {},
      errorsMsg: [],
      userData: {
        address_type: "",
        unit_number: "",
        street_number: "",
        street: "",
        suburb: "",
        state: "",
        post_code: "",
        country_code: "",
        to_date: "",
        from_date: "",
      },
      optionsState: [
        { value: "NSW", text: "New South Wales" },
        { value: "QLD", text: "Queensland" },
        { value: "SA", text: "South Australia" },
        { value: "TAS", text: "Tasmania" },
        { value: "VIC", text: "Victoria" },
        { value: "WA", text: "Western Australia" },
        { value: "ACT", text: "Australian Capital Territory" },
        { value: "NT", text: "Northern Territory" },
        { value: "OTHER", text: "Other" },
      ],
      optionsAddressType: [
        { value: "Postal", text: "Postal" },
        { value: "Previous", text: "Previous" },
      ],
      previous_address_add: [],
    };
  },
  computed: {
    ...mapGetters(["getPoliceCheckSingle", "getSettingAll"]),
  },
  methods: {
    ...mapActions(["fetchPoliceCheckSingle"]),
    fnShowModal() {
      if (this.previous_address) {
        this.previous_address_add = this.previous_address;
      }

      this.userData = this.propsAddressData;
      this.showModal();
    },
    hideModal() {
      this.$refs.previousAddressModal.hide();
    },
    showModal() {
      this.$refs.previousAddressModal.show();
    },
    updatePreviousAddress: async function () {
      let obj1 = {
        address_type: this.userData.address_type,
        unit_number: this.userData.unit_number,
        street_number: this.userData.street_number,
        street: this.userData.street,
        suburb: this.userData.suburb,
        state: this.userData.state,
        post_code: this.userData.post_code,
        country_code: this.userData.country_code,
        to_date: this.userData.to_date,
        from_date: this.userData.from_date,
      };

      this.errorsMsg = this.fnCheckValidation(this.userData);

      if (this.errorsMsg.length === 0) {
        if (this.previous_address_add.length > 0) {
          this.previous_address_add.splice(this.propsAddressIndex, 1);
        }
        this.previous_address_add.push(obj1);

        let obj = {
          previous_address_status: this.previous_address_status,
          previous_address: this.previous_address_add,
        };

        try {
          this.errors = {};

          await policeCheckService.addPreviousAddress(this.policeCheckId, obj);
          this.hideModal();
          this.fetchPoliceCheckSingle(this.policeCheckId);
          messageService.fnToastSuccess(
            "Previous address - Successfully added"
          );

          this.userData = {
            address_type: "",
            unit_number: "",
            street_number: "",
            street: "",
            suburb: "",
            state: "",
            post_code: "",
            country_code: "",
            to_date: "",
            from_date: "",
          };
        } catch (error) {
          switch (error.response.status) {
            case 422:
              this.errors = error.response.data.errors;
              break;

            case 500:
              messageService.fnSweetAlertErrorToast(
                "Previous address",
                error.response.data.message
              );
              break;

            default:
              messageService.fnSweetAlertErrorToast(
                "Previous address",
                "Something wrong"
              );
              break;
          }
        }
      }
    },
    fnCheckValidation(val) {
      let msg = [];

      if (val) {
        if (val.address_type === "") {
          msg.push("Address type is required");
        }
        if (val.street_number === "") {
          msg.push("Street Number is required");
        }
        if (val.street === "") {
          msg.push("Street is required");
        }
        if (val.suburb === "") {
          msg.push("Suburb is required");
        }
        if (val.state === "") {
          msg.push("State is required");
        }
        if (val.post_code === "") {
          msg.push("Post Code is required");
        }
        if (val.post_code === "") {
          msg.push("Post Code is required");
        }
        if (val.country_code === "") {
          msg.push("Country is required");
        }
        if (val.to_date === "") {
          msg.push("To Date is required");
        }
        if (val.from_date === "") {
          msg.push("From Date is required");
        }
        if (val.state === "OTHER" && val.country_code === "AUS") {
          msg.push("Country and State do not match");
        }
        if (val.state !== "OTHER" && val.country_code !== "AUS") {
          msg.push("Country and State do not match");
        }
      } else {
        msg.push("Relevant information is required");
      }

      return msg;
    },
  },
};
</script>
