<template>
    <div>
        <dl class="row">
            <dd class="col-sm-6 text-secondary font-weight-normal">Issuing agency</dd>
            <dd class="col-sm-6">{{ propsData.issuing_agency }}</dd>
            <dd class="col-sm-6 text-secondary font-weight-normal">Given name</dd>
            <dd class="col-sm-6">{{ propsData.given_name }}</dd>
            <dd class="col-sm-6 text-secondary font-weight-normal">Middle name</dd>
            <dd class="col-sm-6">{{ propsData.middle_name }}</dd>
            <dd class="col-sm-6 text-secondary font-weight-normal">Family name</dd>
            <dd class="col-sm-6">{{ propsData.family_name }}</dd>
            <dd class="col-sm-6 text-secondary font-weight-normal">Licence no</dd>
            <dd class="col-sm-6">{{ propsData.licence_no }}</dd>
        </dl>
    </div>
</template>
<script>
export default {
    name:'WDfrmDis05',
    props:['propsData'],
}
</script>