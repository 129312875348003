<template>
    <div>
        <dl class="row">
            <dd class="col-sm-6 text-secondary font-weight-normal">Passport type</dd>
            <dd class="col-sm-6">{{ propsData.passport_type }}</dd>
            <dd class="col-sm-6 text-secondary font-weight-normal">Country</dd>
            <dd class="col-sm-6">{{ propsData.country }}</dd>
            <dd class="col-sm-6 text-secondary font-weight-normal">Number</dd>
            <dd class="col-sm-6">{{ propsData.number }}</dd>
            <dd class="col-sm-6 text-secondary font-weight-normal">Given name</dd>
            <dd class="col-sm-6">{{ propsData.given_name }}</dd>
            <dd class="col-sm-6 text-secondary font-weight-normal">Middle name</dd>
            <dd class="col-sm-6">{{ propsData.middle_name }}</dd>
            <dd class="col-sm-6 text-secondary font-weight-normal">Family name</dd>
            <dd class="col-sm-6">{{ propsData.family_name }}</dd>
        </dl>    
    </div>
</template>
<script>
export default {
    name:'WDfrmDis04',
    props:['propsData'],
}
</script>