<template>
  <div class="row col-container">
    <div class="col-lg-9 col-md-9 col-12 col-card">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              <h5>Police Check Details</h5>
              <div class="underline"></div>
            </div>
          </div>
          <div class="row justify-content-left selectableCard">
            <div
              class="col-sm-2"
              v-for="product in getProductListAccordingToCategory"
              :key="product.id"
            >
              <div
                class="card text-center"
                @click="fnSelectCheckType(product)"
                :class="{ active: step1.product_id == product.id }"
              >
                <div class="card-body">
                  <h4 class="card-text fw-500">{{ product.name }}</h4>
                </div>
              </div>
            </div>
            <div class="invalid-feedback col-12" v-if="errors.product_id">
              {{ errors.product_id[0] }}
            </div>
          </div>

          <div class="row mt-5" v-if="step1.product_id">
            <div class="col-12 mb-3">
              <h5>
                Check details
                <span class="fs-900 text-muted"
                  >(Purpose / Description of duties)
                  <span class="fs-800 text-danger">
                    The Police will reject applications without appropriate
                    details.</span
                  ></span
                >
              </h5>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-6 col-12">
              <div class="form-group" v-if="step1.product_id == 1">
                <b-form-select
                  v-model="step1.category"
                  :options="optionsStandard"
                >
                </b-form-select>
                <label class="floatingLabel vueBootSelect" for="position_title"
                  >Category</label
                >
                <div class="invalid-feedback col-12" v-if="errors.category">
                  {{ errors.category[0] }}
                </div>
              </div>
              <div class="form-group" v-if="step1.product_id == 2">
                <b-form-select
                  v-model="step1.category"
                  :options="optionsVolunteer"
                >
                </b-form-select>
                <label class="floatingLabel vueBootSelect" for="position_title"
                  >Category</label
                >
                <div class="invalid-feedback col-12" v-if="errors.category">
                  {{ errors.category[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-5 col-lg-8 col-md-8 col-12">
              <div
                class="form-group"
                v-if="step1.product_id == 1 && step1.category == 'Employment'"
              >
                <input
                  class="form-control"
                  type="text"
                  id="position_title"
                  autocomplete="off_position_title"
                  placeholder=" "
                  v-model="step1.position_title"
                />
                <label class="floatingLabel" for="position_title"
                  >Position title/Occupation
                  <span class="fs-800"> (Eg. Nurse)</span></label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.position_title"
                >
                  {{ errors.position_title[0] }}
                </div>
              </div>
              <div
                class="form-group"
                v-if="step1.product_id == 1 && step1.category == 'Licence'"
              >
                <input
                  class="form-control"
                  type="text"
                  id="licence_type_sought"
                  autocomplete="off_licence_type_sought"
                  placeholder=" "
                  v-model="step1.licence_type_sought"
                />
                <label class="floatingLabel" for="licence_type_sought"
                  >Licence type sought</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.licence_type_sought"
                >
                  {{ errors.licence_type_sought[0] }}
                </div>
              </div>
              <div class="form-group" v-if="step1.category == 'Probity'">
                <input
                  class="form-control"
                  type="text"
                  id="check_purpose"
                  autocomplete="off_check_purpose"
                  placeholder=" "
                  v-model="step1.check_purpose"
                />
                <label class="floatingLabel" for="check_purpose"
                  >Check purpose</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.check_purpose"
                >
                  {{ errors.check_purpose[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-5 col-lg-8 col-md-8 col-12">
              <div
                class="form-group"
                v-if="step1.product_id == 1 && step1.category == 'Employment'"
              >
                <input
                  class="form-control"
                  type="text"
                  id="place_of_work"
                  autocomplete="off_place_of_work"
                  placeholder=" "
                  v-model="step1.place_of_work"
                />
                <label class="floatingLabel" for="place_of_work"
                  >Employer/Industry
                  <span class="fs-800"> (Eg. Name of hospital)</span></label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.place_of_work"
                >
                  {{ errors.place_of_work[0] }}
                </div>
              </div>
              <div
                class="form-group"
                v-if="step1.product_id == 1 && step1.category == 'Licence'"
              >
                <input
                  class="form-control"
                  type="text"
                  id="issuing_authority"
                  autocomplete="off_issuing_authority"
                  placeholder=" "
                  v-model="step1.issuing_authority"
                />
                <label class="floatingLabel" for="issuing_authority"
                  >Issuing authority</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.issuing_authority"
                >
                  {{ errors.issuing_authority[0] }}
                </div>
              </div>
              <div class="form-group" v-if="step1.category == 'Probity'">
                <input
                  class="form-control"
                  type="text"
                  id="requesting_organisation"
                  autocomplete="off_requesting_organisation"
                  placeholder=" "
                  v-model="step1.requesting_organisation"
                />
                <label class="floatingLabel" for="requesting_organisation"
                  >Requesting organisation/entity</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.requesting_organisation"
                >
                  {{ errors.requesting_organisation[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="step1.product_id">
            <div class="col-xl-5 col-lg-5 col-md-5 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="location_Town"
                  autocomplete="off_location_Town"
                  placeholder=" "
                  v-model="step1.location_town"
                />
                <label class="floatingLabel" for="location_Town"
                  >Location/Town</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.location_town"
                >
                  {{ errors.location_town[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-12">
              <b-form-select v-model="step1.state_code" :options="optionsState">
              </b-form-select>
              <label
                class="floatingLabel vueBootSelect"
                for="requesting_organisation"
                >State</label
              >
              <div class="invalid-feedback col-12" v-if="errors.state_code">
                {{ errors.state_code[0] }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right pt2000">
              <button class="btn-info btn-sm btn-w-100" @click="fnNextStep()">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-12 col-card">
      <div class="card h-overflow-500">
        <div class="card-body">
          <appStep1Ins></appStep1Ins>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as messageService from "../../services/message.service";
import * as authService from "../../services/auth.service";
import * as policeCheckService from "../../services/policeCheck.service";
import { mapGetters, mapActions } from "vuex";
import appStep1Ins from "../../components/policeCheck/step1Ins.vue";
import * as encodeDecodeService from "../../services/encodeDecode.service";

export default {
  name: "step1",
  props: ["policeCheckId", "mode", "shId"],
  components: {
    appStep1Ins,
  },
  data() {
    return {
      loginUserObj: {},
      paramStepId: this.$route.params.paramStepId,
      paramPartnerUserId: this.$route.params.user_id,
      // policeCheckId: encodeDecodeService.decode(this.$route.params.id),
      // paramWay:this.$route.params.way,
      errors: {},
      step1: {
        sh_id: "",
        product_id: "",
        category: "",
        position_title: "",
        licence_type_sought: "",
        check_purpose: "",
        place_of_work: "",
        issuing_authority: "",
        requesting_organisation: "",
        location_town: "",
        state_code: "",
        user_id: "",
        submitStatus: false,
      },
      optionsStandard: [
        { value: "Employment", text: "Employment" },
        { value: "Licence", text: "Licence" },
        { value: "Probity", text: "Probity" },
      ],
      optionsVolunteer: [{ value: "Probity", text: "Probity" }],
      optionsState: [
        { value: "NSW", text: "New South Wales" },
        { value: "QLD", text: "Queensland" },
        { value: "SA", text: "South Australia" },
        { value: "TAS", text: "Tasmania" },
        { value: "VIC", text: "Victoria" },
        { value: "WA", text: "Western Australia" },
        { value: "ACT", text: "Australian Capital Territory" },
        { value: "NT", text: "Northern Territory" },
        { value: "CWT", text: "Commonwealth" },
        { value: "OTH", text: "Other" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "gettPoliceCheckType",
      "getPoliceCheckSingle",
      "getProductListAccordingToCategory",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchStepId",
      "fetchPoliceCheckType",
      "fetchPoliceCheckSingle",
      "fetchProductListAccordingToCategory",
    ]),
    fnSelectCheckType(checkType) {
      this.step1 = {};
      this.step1.product_id = checkType.id;
      this.step1.fee = checkType.unit_price;
      this.step1.sh_id = this.shId;
      this.fetchPoliceCheckType(checkType.id);
    },
    fnNextStep() {
      if (this.loginUserObj.role_id == 3 && this.mode == "new") {
        this.create();
      }

      // Partner and Partner Staff
      if (
        (this.loginUserObj.role_id == 6 || this.loginUserObj.role_id == 7) &&
        this.mode == "new"
      ) {
        this.create();
      }

      if (
        (this.loginUserObj.role_id == 1 ||
          this.loginUserObj.id == 2 ||
          this.loginUserObj.role_id == 3 ||
          this.loginUserObj.role_id == 6 ||
          this.loginUserObj.role_id == 7) &&
        this.mode == "edit"
      ) {
        this.update();
      }
    },
    create: async function () {
      if (this.loginUserObj.role_id == 3) {
        this.step1.user_id = this.loginUserObj.id;
      }

      if (this.loginUserObj.role_id == 6 || this.loginUserObj.role_id == 7) {
        this.step1.user_id = this.paramPartnerUserId;
      }

      try {
        const response = await policeCheckService.add(
          JSON.stringify(this.step1)
        );

        messageService.fnToastSuccess("Started new police check successfully");

        if (response.status === 200) {
          const pId = response.data.data.id;

          if (
            this.loginUserObj.role_id == 6 ||
            this.loginUserObj.role_id == 7
          ) {
            this.$router.push({
              name: "PoliceCheckStepsEditPartner",
              params: {
                userId: encodeDecodeService.encode(this.paramPartnerUserId),
                id: encodeDecodeService.encode(pId),
                paramStepId: 2,
              },
            });
          } else {
            this.$router.push({
              name: "PoliceCheckIUStepsEdit",
              params: {
                userId: encodeDecodeService.encode(this.loginUserObj.id),
                id: encodeDecodeService.encode(pId),
                paramStepId: 2,
              },
            });
          }
        }
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Police check - new",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Police check - new",
              "Something wrong"
            );
            break;
        }
      }
    },
    update: async function () {
      try {
        const response = await policeCheckService.update(
          this.policeCheckId,
          JSON.stringify(this.step1)
        );

        if (response.status === 200) {
          this.fetchPoliceCheckSingle(this.policeCheckId);
          this.fetchStepId(2);
        }
        messageService.fnToastSuccess("Updated police check successfully");
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Police check",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Police check",
              "Something wrong"
            );
            break;
        }
      }
    },
  },
  created() {
    this.loginUserObj = authService.getUserFromToken();

    this.fetchProductListAccordingToCategory(1);
    if (this.paramStepId == 2) {
      this.fetchStepId(2);
    }
  },
  mounted() {
    if (this.getPoliceCheckSingle && this.mode == "edit") {
      this.step1.product_id = this.getPoliceCheckSingle.product_id;
      this.step1.fee = this.getPoliceCheckSingle.fee;
      this.step1.category = this.getPoliceCheckSingle.category;
      this.step1.position_title = this.getPoliceCheckSingle.position_title;
      this.step1.licence_type_sought =
        this.getPoliceCheckSingle.licence_type_sought;
      this.step1.check_purpose = this.getPoliceCheckSingle.check_purpose;
      this.step1.place_of_work = this.getPoliceCheckSingle.place_of_work;
      this.step1.issuing_authority =
        this.getPoliceCheckSingle.issuing_authority;
      this.step1.requesting_organisation =
        this.getPoliceCheckSingle.requesting_organisation;
      this.step1.location_town = this.getPoliceCheckSingle.location_town;
      this.step1.state_code = this.getPoliceCheckSingle.state_code;
      this.fetchPoliceCheckType(this.getPoliceCheckSingle.product_id);
    }
  },

  watch: {
    policeCheckId: function (val) {
      this.fetchPoliceCheckSingle(this.policeCheckId);
    },

    getPoliceCheckSingle: function (val) {
      if (val) {
        this.step1.product_id = val.product_id;
        this.step1.fee = val.fee;
        this.step1.category = val.category;
        this.step1.position_title = val.position_title;
        this.step1.licence_type_sought = val.licence_type_sought;
        this.step1.check_purpose = val.check_purpose;
        this.step1.place_of_work = val.place_of_work;
        this.step1.issuing_authority = val.issuing_authority;
        this.step1.requesting_organisation = val.requesting_organisation;
        this.step1.location_town = val.location_town;
        this.step1.state_code = val.state_code;
        this.fetchPoliceCheckType(val.product_id);
      }
    },
  },
};
</script>
