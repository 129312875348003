<template>
  <div class="card sits-border-info">
    <div class="card-header">
      <h5 class="text-info mb-0">Additional Details</h5>
    </div>

    <div class="card-body">
      <div>
        <dl class="row">
          <dt class="col-sm-6">I have maiden/previous/alias names</dt>
          <dd class="col-sm-6">{{ propsData.previous_names_status }}</dd>
        </dl>
        <div v-if="propsData.previous_names">
          <dl
            class="row"
            v-for="(item, index) in propsData.previous_names"
            :key="index"
          >
            <dd class="col-sm-6 fw-700 text-danger">{{ item.name_type }}</dd>
            <dd class="col-sm-6"></dd>
            <dd class="col-sm-6 fw-700">First name</dd>
            <dd class="col-sm-6">{{ item.first_name }}</dd>
            <dd class="col-sm-6 fw-700">Middle name</dd>
            <dd class="col-sm-6">{{ item.middle_name }}</dd>
            <dd class="col-sm-6 fw-700">Family name</dd>
            <dd class="col-sm-6">{{ item.last_name }}</dd>
            <dd class="col-sm-6 fw-700">Date of change</dd>
            <dd class="col-sm-6">{{ item.date_of_change }}</dd>
          </dl>
        </div>

        <dl class="row">
          <dt class="col-sm-6">
            I have lived at the same address for the last 5 years
          </dt>
          <dd class="col-sm-6">{{ propsData.previous_address_status }}</dd>
        </dl>
        <div v-if="propsData.previous_address">
          <dl
            class="row"
            v-for="(item, index) in propsData.previous_address"
            :key="index"
          >
            <dd class="col-sm-6 fw-700 text-danger">
              {{ item.address_type }}
            </dd>
            <dd class="col-sm-6"></dd>
            <dd class="col-sm-6 fw-700">Unit number</dd>
            <dd class="col-sm-6">{{ item.unit_number }}</dd>
            <dd class="col-sm-6 fw-700">Street number</dd>
            <dd class="col-sm-6">{{ item.street_number }}</dd>
            <dd class="col-sm-6 fw-700">Street</dd>
            <dd class="col-sm-6">{{ item.street }}</dd>
            <dd class="col-sm-6 fw-700">Suburb</dd>
            <dd class="col-sm-6">{{ item.suburb }}</dd>
            <dd class="col-sm-6 fw-700">State</dd>
            <dd class="col-sm-6">{{ item.state }}</dd>
            <dd class="col-sm-6 fw-700">Post code</dd>
            <dd class="col-sm-6">{{ item.post_code }}</dd>
            <dd class="col-sm-6 fw-700">Country</dd>
            <dd class="col-sm-6">{{ item.country }}</dd>
            <dd class="col-sm-6 fw-700">Living from date</dd>
            <dd class="col-sm-6">{{ item.from_date }}</dd>
            <dd class="col-sm-6 fw-700">Living to date</dd>
            <dd class="col-sm-6">{{ item.to_date }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pcAdditionalDetailsCom",
  props: {
    propsData: {
      required: true,
      type: Object,
    },
  },
};
</script>