<template>
    <div>
        <dl class="row">
            <dd class="col-sm-6 text-secondary font-weight-normal">Cannot provide document</dd>
            <dd class="col-sm-6">{{ propsData.cannot_provide_document }}</dd>
        </dl>
    </div>
</template>
<script>
export default {
    name:'WDfrmDis08',
    props:['propsData'],
}
</script>