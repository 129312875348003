<template>
  <div class="card sits-border-info">
    <div class="card-header">
      <h5 class="text-info mb-0">Identity Verification Photo</h5>
    </div>
    <div class="card-body">
      <dl class="row">
        <dd class="col-sm-4 fw-700">Uploaded photo</dd>
        <dd class="col-sm-8">
          <div class="p-1" v-if="propsData.photo">
            <div class="text-center mt-1">
              <wdImageAvatarPreview
                :title="'Identity Verification Photo'"
                :coverImgUrl="propsData.photo"
                :fontAwClass="'fas fa-file-alt'"
              ></wdImageAvatarPreview>
            </div>
          </div>
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import wdImageAvatarPreview from "../../components/wd/WDImageAvatarPreview.vue";
export default {
  name: "pcIdentityVerificationPhotoCom",
  props: {
    propsData: {
      required: true,
      type: Object,
    },
  },
  components: {
    wdImageAvatarPreview,
  },
};
</script>