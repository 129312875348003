<template>
  <div class="row">
    <div class="col-lg-9 col-md-9 col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              <h5>Payment</h5>
              <div class="underline"></div>
            </div>
          </div>

          <div class="row justify-content-center selectableCard">
            <div class="col-lg-4 col-12">
              <div class="d-flex justify-content-center">
                <img
                  src="../../assets/img/credit_card_logos_x_4.png"
                  class="pb-3"
                />
              </div>

              <div
                class="sits-border-info p-3"
                v-if="!getPoliceCheckSingle.payment_ref"
              >
                <dl class="row">
                  <dd class="col-sm-8">
                    Police check - {{ getPoliceCheckSingle.product.name }}
                  </dd>
                  <dd class="col-sm-4 text-right">
                    {{ getPoliceCheckSingle.product.unit_price }}
                  </dd>
                  <dt class="col-sm-8 fw-700">Total</dt>
                  <dd class="col-sm-4 fw-700 text-right">
                    {{ getPoliceCheckSingle.product.unit_price }}
                  </dd>
                </dl>
              </div>

              <div
                class="sits-border-info p-3 text-center"
                v-if="getPoliceCheckSingle.payment_ref"
              >
                <p class="mb-0">
                  Payment completed
                  <i class="far fa-check-circle text-success"></i>
                </p>
              </div>

              <div ref="card" v-if="!getPoliceCheckSingle.payment_ref"></div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-right pt2000">
              <button
                class="btn-secondary btn-sm mr-2 btn-w-100"
                @click="fnPreviousStep()"
              >
                Previous
              </button>
              <button
                v-if="!getPoliceCheckSingle.payment_ref"
                class="btn-info btn-sm btn-w-100"
                @click="processPayment()"
              >
                <b-spinner small v-if="loading" class="mr-2"></b-spinner>
                <span v-if="loading">Loading...</span>
                <span v-else>Pay now</span>
              </button>
              <button
                v-if="getPoliceCheckSingle.payment_ref"
                class="btn-info btn-sm btn-w-100"
                @click="fnNextStep()"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-12">
      <div class="card h-overflow">
        <div class="card-body">
          <appStep3Ins></appStep3Ins>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import * as messageService from "../../services/message.service";
import * as paymentService from "../../services/payment.service";
import appStep3Ins from "../../components/policeCheck/step3Ins.vue";
import * as encodeDecodeService from "../../services/encodeDecode.service";
import baseModule from "../../store/modules/base.module";

var style = {
  base: {
    iconColor: "#c4f0ff",
    color: "#32325d",
    fontWeight: "500",
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    fontSize: "16px",
    fontSmoothing: "antialiased",
    ":-webkit-autofill": {
      color: "#fce883",
    },
    "::placeholder": {
      color: "#aab7c4",
    },
  },
  invalid: {
    iconColor: "#e65350",
    color: "#e65350",
  },
};
var card = undefined;

export default {
  name: "step3",
  props: ["mode", "policeCheckId"],
  components: {
    appStep3Ins,
  },
  async mounted() {
    const stripePub = baseModule.state.stripePub;

    this.stripe = await loadStripe(stripePub);

    const elements = this.stripe.elements();

    card = elements.create("card", {
      style: style,
      hidePostalCode: true,
    });
    card.mount(this.$refs.card);
  },

  data() {
    return {
      token: null,
      loading: false,
      step1: {
        checkType: "",
      },
      stripe: {},
      cardElement: {},
      charge: {},
      errors: {},
    };
  },
  computed: {
    ...mapGetters(["getSettingAll", "getPoliceCheckSingle"]),
  },

  methods: {
    ...mapActions(["fetchStepId", "fetchPoliceCheckSingle"]),
    fnPreviousStep() {
      this.fetchStepId(2);
    },
    fnNextStep() {
      this.fetchStepId(4);
    },

    fnSelectCheckType(checkType) {
      this.step1.checkType = checkType;
    },
    processPayment() {
      this.stripe.createToken(card).then((result) => {
        if (result.error) {
          console.log("Error happened when getting token");
        } else {
          this.handelToken(result.token);
        }
      });
    },
    handelToken(token) {
      try {
        this.loading = true;

        const apiURL = baseModule.state.apiURL;

        this.charge.stripeToken = token.id;
        this.charge.pId = this.policeCheckId;
        this.charge.amount = this.getPoliceCheckSingle.fee;
        this.charge.customer =
          this.getPoliceCheckSingle.profile_info.first_name +
          " " +
          this.getPoliceCheckSingle.profile_info.last_name;
        this.charge.receipt_email =
          this.getPoliceCheckSingle.profile_info.email;
        this.charge.payment_description =
          "PID-" +
          this.policeCheckId +
          " | " +
          "Police-check" +
          " | " +
          this.getPoliceCheckSingle.profile_info.first_name +
          " " +
          this.getPoliceCheckSingle.profile_info.last_name;

        axios
          .post(apiURL + `/police-check/charge`, this.charge)
          .then((response) => {
            if (response.data.status == "succeeded" && response.data.id) {
              this.fetchPoliceCheckSingle(this.policeCheckId);
              this.fetchStepId(4);

              messageService.fnToastSuccess("Payment made successfully!");
              this.loading = false;
            }
          })
          .catch((error) => {
            alert(error);
          });
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Payment",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Payment",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Payment",
              "Something wrong, please try again!"
            );
            break;
        }
        messageService.fnToastError("Something wrong, please try again!");
      }
    },
  },
  created() {
    this.fetchPoliceCheckSingle(this.policeCheckId);
  },
};
</script>
<style scoped>
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement--invalid {
  border-color: #e65350;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.StripeElement {
  border: solid 1px #ddd;
  border-radius: 3px;
}
</style>
