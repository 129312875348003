<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h5 class="text-info mb-0">Check List (Admin/Staff)</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div v-if="userData">
              <dl class="row">
                <dd class="col-sm-5 fw-700">Ref. no</dd>
                <dd class="col-sm-7">
                  {{ userData.id }}
                </dd>
                <dd class="col-sm-5 fw-700">Documents checked</dd>
                <dd class="col-sm-7">
                  <b-form-group v-slot="{ ariaDescribedby }" v-if="propsData">
                    <b-form-checkbox-group
                      v-model="docSited"
                      :options="optionsCheckList"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-2a"
                      stacked
                    ></b-form-checkbox-group>
                  </b-form-group>
                </dd>
              </dl>
            </div>

            <div class="text-right">
              <button class="btn btn-sm btn-info" @click="fnUpdate()">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as policeCheckService from "../../services/policeCheck.service";
export default {
  name: "pcCheckListCom",
  props: ["propsData"],
  data() {
    return {
      checkToReady: "",
      content: "Note here",
      docSited: [],
      optionsCheckList: [
        { text: "Commencement", value: "Commencement" },
        { text: "Primary", value: "Primary" },
        { text: "Secondary - 1", value: "Secondary - 1" },
        { text: "Secondary - 2", value: "Secondary - 2" },
      ],
      userData: {},
    };
  },
  computed: {
    ...mapGetters(["getPoliceCheckSingle"]),
  },
  methods: {
    ...mapActions(["fetchPoliceCheckSingle", "fetchPoliceCheckList"]),
    fnUpdate() {
      this.update();
      this.fetchPoliceCheckSingle(this.propsData.id);
      // this.fetchPoliceCheckList();
    },

    update: async function () {
      try {
        let obj1 = this.getPoliceCheckSingle;
        let obj2 = {
          check_list: this.docSited,
        };
        let obj = {
          ...obj1,
          ...obj2,
        };
        await policeCheckService.update(this.propsData.id, obj);

        messageService.fnToastSuccess("Updated police check successfully");
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Police check",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Police check",
              "Something wrong"
            );
            break;
        }
      }
    },
  },

  watch: {
    propsData: function (val) {
      this.userData = val;
      if (val.check_list) {
        this.docSited = val.check_list;
      }
    },
  },
};
</script>
