<template>
  <div class="card-columns">
    <!-- Check Details -->
    <appPcCheckDetails :propsData="propsData"> </appPcCheckDetails>

    <!-- Personal Details -->
    <appPcPersonalDetails :propsData="propsData"></appPcPersonalDetails>

    <!-- Identity Verification Photo -->
    <appPcIdentityVerificationPhoto
      :propsData="propsData"
    ></appPcIdentityVerificationPhoto>

    <!-- Current Address Details -->
    <appPcCurrentAddressDetails
      :propsData="propsData"
    ></appPcCurrentAddressDetails>

    <!-- Upload Identity Documents -->
    <appPcUploadIdentityDocuments
      :propsData="propsData"
    ></appPcUploadIdentityDocuments>

    <!-- Additional Details -->
    <appPcAdditionalDetails :propsData="propsData"></appPcAdditionalDetails>
  </div>
</template>
<script>
import appPcCheckDetails from "../../components/policeCheck/pcCheckDetails.vue";
import appPcPersonalDetails from "../../components/policeCheck/pcPersonalDetails.vue";
import appPcIdentityVerificationPhoto from "../../components/policeCheck/pcIdentityVerificationPhoto.vue";
import appPcCurrentAddressDetails from "../../components/policeCheck/pcCurrentAddressDetails.vue";
import appPcUploadIdentityDocuments from "../../components/policeCheck/pcUploadIdentityDocuments.vue";
import appPcAdditionalDetails from "../../components/policeCheck/pcAdditionalDetails.vue";
export default {
  name: "reviewBlock1",
  props: {
    propsData: {
      required: true,
      type: Object,
    },
  },
  components: {
    appPcCheckDetails,
    appPcPersonalDetails,
    appPcIdentityVerificationPhoto,
    appPcCurrentAddressDetails,
    appPcUploadIdentityDocuments,
    appPcAdditionalDetails,
  },
};
</script>